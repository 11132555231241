import * as React from "react";
import styled, { keyframes } from "styled-components";
import { device } from "../../../utils/constants";
import { Modal, ModalBody } from "reactstrap";
import { slideInUp } from "react-animations";
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";

const bounceAnimation = keyframes`${slideInUp}`;

export const Container = styled.div`
  width: 50vw;
  height: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.mobile} {
    width: 100vw;
    height: 50vw;
  }
`;

export const BodyInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
  position: absolute;

  animation: 0.5s ${bounceAnimation};
`;
export const Info = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-weight: normal;
    font-size: 1.8em;
    text-align: center;
  }
`;

export const Links = styled.h2`
  cursor: pointer;
  text-align: center;
`;

const PublicidadComponent = ({ img, title, desc, url }) => {
  const [isHover, setIsHover] = React.useState(false);
  const [isModal, setIsModal] = React.useState(false);

  const toggle = () => {
    setIsModal(!isModal);
  };

  const info = (
    <BodyInfo>
      <Info>
        <h3>{title}</h3>
        {url === "" ? <h1>{desc}</h1> : <Links onClick={toggle}>{desc}</Links>}
      </Info>
    </BodyInfo>
  );

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  return (
    <Container
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      img={img}
    >
      {isHover ? info : <></>}

      <Modal isOpen={isModal} toggle={toggle} centered={true} size="lg">
        <ModalBody style={{ backgroundColor: "black" }}>
          <ReactPlayer
            width={isMobileDevice ? "100%" : null}
            url={url}
            playing
            controls
          />
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default PublicidadComponent;
