import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import { getAllSuscripcionesService } from '../../../../services';
import {
  checkMensajeSuscripcionesAction,
  getAllSuscripcionesAction,
  setActivoSuscripcionesAction,
} from '../../../../store/actions';
import { GET_CLIENTES } from '../../../../store/types';

const Container = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;

  .botones {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;
const Tag = styled.label`
  font-size: ${(props) => props.size ?? '8pt'};
  color: ${(props) => (props.leido ? 'green' : 'red')};
  margin: 0 10px;
  cursor: pointer;
`;

const Nombre = styled.div`
  cursor: pointer;
  label {
    cursor: pointer;
  }
`;

const Links = styled.div`
  display: flex;

  a {
    &:hover {
      font-size: 1.04vw;
    }
  }

  .wasa {
    margin-left: 1vw;
  }
`;

const Listado = () => {
  const dispatch = useDispatch();
  const suscripcionesState = useSelector((state) => state.suscripciones);

  const [isModal, setIsModal] = useState();
  const [loadingCheck, setLoadingCheck] = useState();
  const [loadingRefresh, setLoadingRefresh] = useState();
  const [loadingData, setLoadingData] = useState();

  const getAllSuscripciones = async () => {
    await getAllSuscripcionesAction(dispatch, null);
    setLoadingRefresh(false);
  };

  useEffect(() => {
    getAllSuscripciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: 'ID',
      width: '60px',
      selector: (row) => row.id,
      sortable: true,
    },
    // {
    //   name: "Códigos",
    //   width: "210px",
    //   sortable: true,
    //   cell: (row) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "column",
    //       }}
    //     >
    //       {row.codigos.map((el) => {
    //         return (
    //           <label>
    //             <Tag leido={el.activo}>{el.activo ? "Act." : "Blo."}</Tag>{" "}
    //             {el.codigo}
    //             {"->"}
    //             {el.tipoCodigo.nombre}
    //           </label>
    //         );
    //       })}
    //     </div>
    //   ),
    // },
    {
      name: 'Nombre y apellido',
      width: '250px',
      sortable: true,
      cell: (row) => (
        <Nombre
          onClick={async () => {
            await setActivoSuscripcionesAction(dispatch, row);
            setIsModal(true);
          }}
        >
          <label>{row.fullname}&nbsp;&nbsp;</label>
          <Tag leido={row.check}>{!row.check ? 'Sin leer' : 'Leído'}</Tag>
        </Nombre>
      ),
    },
    {
      name: 'Correo',
      width: '250px',
      sortable: true,
      cell: (row) => <a href={`mailto:${row.email}`}>{row.email}</a>,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const handleCheck = async () => {
    console.log(suscripcionesState.activo);
    setLoadingCheck(true);

    const res = await checkMensajeSuscripcionesAction(
      dispatch,
      suscripcionesState.activo.id
    );
    if (res) {
      getAllSuscripciones();
      setLoadingCheck(false);
    }
  };

  const toggle = () => setIsModal(!isModal);

  const fetchUsers = async (page) => {
    setLoadingData(true);
    const res = await getAllSuscripcionesService({
      page,
      limit: suscripcionesState.meta.itemsPerPage,
    });
    dispatch({
      type: GET_CLIENTES,
      payload: res,
    });
    setLoadingData(false);
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoadingData(true);
    const res = await getAllSuscripcionesService({ page, limit: newPerPage });
    dispatch({
      type: GET_CLIENTES,
      payload: res,
    });
    setLoadingData(false);
  };

  return (
    <>
      <Container>
        <div className="botones">
          <Button
            disabed={loadingRefresh}
            onClick={() => {
              setLoadingRefresh(true);
              getAllSuscripciones();
            }}
          >
            {loadingRefresh ? (
              <>
                <Spinner size="sm" />
                &nbsp;&nbsp;Actualizando lista...
              </>
            ) : (
              'Actualizar'
            )}
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={suscripcionesState.all}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          dense
          striped
          pointerOnHover
          highlightOnHover
          onRowClicked={async (row, event) => {
            await setActivoSuscripcionesAction(dispatch, row);
            setIsModal(true);
          }}
          paginationPerPage={
            suscripcionesState.meta !== null &&
            suscripcionesState.meta !== undefined
              ? suscripcionesState.meta.itemsPerPage
              : 50
          }
          paginationRowsPerPageOptions={[10, 30, 50]}
          paginationTotalRows={
            suscripcionesState.meta !== null &&
            suscripcionesState.meta !== undefined
              ? suscripcionesState.meta.totalItems
              : 0
          }
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationServer
          progressPending={loadingData}
        />
        {suscripcionesState.activo !== null ? (
          <Modal
            isOpen={isModal}
            toggle={toggle}
            centered
            style={{ maxWidth: '70%' }}
          >
            <ModalHeader toggle={toggle}>
              {`${suscripcionesState.activo.id} - ${suscripcionesState.activo.fullname}`}
              <Tag size={'12pt'} leido={suscripcionesState.activo.check}>
                {!suscripcionesState.activo.check ? 'Sin leer' : 'Leído'}
              </Tag>
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-2">
                  <strong>Correo:</strong>
                </div>
                <div className="col">
                  <Links>
                    <a
                      href={`mailto:${suscripcionesState.activo.email}`}
                      style={{
                        textDecoration: 'none',
                        color: 'blue',
                      }}
                    >
                      {suscripcionesState.activo.email}
                    </a>
                  </Links>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <strong>Fecha:</strong>
                </div>
                <div className="col">
                  {dayjs(suscripcionesState.activo.fecha).format(
                    'DD-MM-YYYY HH:mm:ss A'
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {suscripcionesState.activo.check ? null : (
                <Button
                  color="success"
                  disabled={loadingCheck}
                  onClick={handleCheck}
                >
                  {loadingCheck ? (
                    <>
                      <Spinner size="sm" />
                      <span> Procesando...</span>
                    </>
                  ) : (
                    'Marcar como leído'
                  )}
                </Button>
              )}
            </ModalFooter>
          </Modal>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default Listado;
