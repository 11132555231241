import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import styled from 'styled-components';
import { getAllPageClientesService } from '../../../../services';
import {
  activoAction,
  checkMensajeClientesAction,
  getAllClientesAction,
  setActivoClientesAction,
} from '../../../../store/actions';
import { GET_CLIENTES } from '../../../../store/types';
const dayjs = require('dayjs');

const Container = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;

  .botones {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;
const Tag = styled.label`
  font-size: ${(props) => props.size ?? '8pt'};
  color: ${(props) => (props.leido ? 'green' : 'red')};
  margin: 0 5px;
  cursor: pointer;
`;

const Nombre = styled.div`
  cursor: pointer;
  label {
    cursor: pointer;
  }
`;

const Links = styled.div`
  display: flex;

  a {
    &:hover {
      font-size: 1.04vw;
    }
  }

  .wasa {
    margin-left: 1vw;
  }
`;

const Iconos = styled.img`
  width: 1.5vw;
  height: ${(props) => props.h};
  cursor: pointer;
  margin-bottom: 0.7vw;

  &:hover {
    transform: scale(1.2);
  }
`;

const Listado = () => {
  const dispatch = useDispatch();
  const clientesState = useSelector((state) => state.clientes);

  const [isModal, setIsModal] = useState();
  const [loadingCheck, setLoadingCheck] = useState();
  const [loadingRefresh, setLoadingRefresh] = useState();
  const [loadingData, setLoadingData] = useState();

  const [loadingEstatusCine, setLoadingEstatusCine] = useState(false);
  const [loadingEstatusPro, setLoadingEstatusPro] = useState(false);

  const getAllClientes = async () => {
    await getAllClientesAction({ dispatch });
    setLoadingRefresh(false);
  };

  useEffect(() => {
    getAllClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: 'ID',
      width: '60px',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Códigos',
      width: '200px',
      sortable: true,
      cell: (row) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {row.codigos.length > 0
            ? row.codigos.map((el) => {
                return (
                  <label>
                    <Tag leido={el.activo}>{el.activo ? 'Act.' : 'Blo.'}</Tag>
                    {el.codigo}
                    {'-'}
                    {el.tipoCodigo.nombre}
                  </label>
                );
              })
            : 'Sin codigo'}
        </div>
      ),
    },
    {
      name: 'Nombre y apellido',
      width: '200px',
      sortable: true,
      cell: (row) => (
        <Nombre
          onClick={async () => {
            await setActivoClientesAction({ dispatch, data: row });
            setIsModal(true);
          }}
        >
          <label>{row.nombre}&nbsp;&nbsp;</label>
          <label>{row.apellido}</label>
          <Tag leido={row.isLeido}>{!row.isLeido ? 'Sin leer' : 'Leído'}</Tag>
        </Nombre>
      ),
    },
    {
      name: 'Teléfono',
      selector: (row) => row.telefono,
      sortable: true,
    },
    {
      name: 'Correo',
      selector: (row) => row.correo,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row) => dayjs(row.fecha).format('YYYY-MM-DD HH:mm a'),
      sortable: true,
    },
    {
      name: 'Descripción',
      selector: (row) => row.desc,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const handleCheck = async () => {
    console.log(clientesState.activo);
    setLoadingCheck(true);

    const res = await checkMensajeClientesAction({
      dispatch,
      id: clientesState.activo.id,
    });
    if (res) {
      getAllClientes();
      setLoadingCheck(false);
    }
  };

  const toggle = () => setIsModal(!isModal);

  const fetchUsers = async (page) => {
    setLoadingData(true);
    const res = await getAllPageClientesService(
      page,
      clientesState.meta.itemsPerPage
    );
    dispatch({
      type: GET_CLIENTES,
      payload: res,
    });
    setLoadingData(false);
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoadingData(true);
    const res = await getAllPageClientesService(page, newPerPage);
    dispatch({
      type: GET_CLIENTES,
      payload: res,
    });
    setLoadingData(false);
  };

  return (
    <>
      <Container>
        <div className="botones">
          <Button
            disabed={loadingRefresh}
            onClick={() => {
              setLoadingRefresh(true);
              getAllClientes();
            }}
          >
            {loadingRefresh ? (
              <>
                <Spinner size="sm" />
                &nbsp;&nbsp;Actualizando lista...
              </>
            ) : (
              'Actualizar'
            )}
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={clientesState.all}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          dense
          striped
          pointerOnHover
          highlightOnHover
          onRowClicked={async (row, event) => {
            await setActivoClientesAction({ dispatch, data: row });
            setIsModal(true);
          }}
          paginationPerPage={
            clientesState.meta !== null && clientesState.meta !== undefined
              ? clientesState.meta.itemsPerPage
              : 50
          }
          paginationRowsPerPageOptions={[10, 30, 50]}
          paginationTotalRows={
            clientesState.meta !== null && clientesState.meta !== undefined
              ? clientesState.meta.totalItems
              : 0
          }
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationServer
          progressPending={loadingData}
        />
        {clientesState.activo !== null ? (
          <Modal
            isOpen={isModal}
            toggle={toggle}
            centered
            style={{ maxWidth: '70%' }}
          >
            <ModalHeader toggle={toggle}>
              {`${clientesState.activo.id} - ${clientesState.activo.nombre} ${clientesState.activo.apellido}`}
              <Tag size={'12pt'} leido={clientesState.activo.isLeido}>
                {!clientesState.activo.isLeido ? 'Sin leer' : 'Leído'}
              </Tag>
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="row">
                  <div className="col-2">
                    <strong>Códigos:</strong>
                  </div>
                  {clientesState.activo.codigos.length > 0 ? (
                    <>
                      <div className="col-4" style={{ fontWeight: 'bold' }}>
                        Cine: {clientesState.activo.codigos[0].codigo}
                        &nbsp;&nbsp;
                        {loadingEstatusCine ? (
                          <Spinner size="sm" />
                        ) : (
                          <Tag
                            size={'12pt'}
                            leido={!clientesState.activo.codigos[0].activo}
                            onClick={() => {
                              setLoadingEstatusCine(true);
                              setTimeout(() => {
                                const res = activoAction(
                                  dispatch,
                                  clientesState.activo.id,
                                  clientesState.activo.codigos[0].id,
                                  clientesState.activo.codigos[0].activo
                                );
                                setLoadingEstatusCine(!res);
                              }, 500);
                            }}
                          >
                            {clientesState.activo.codigos[0].activo
                              ? 'Bloquaer'
                              : 'Activar'}
                          </Tag>
                        )}
                      </div>
                      <div className="col-6" style={{ fontWeight: 'bold' }}>
                        Propaganda: {clientesState.activo.codigos[1].codigo}
                        &nbsp;&nbsp;
                        {loadingEstatusPro ? (
                          <Spinner size="sm" />
                        ) : (
                          <Tag
                            size={'12pt'}
                            leido={!clientesState.activo.codigos[1].activo}
                            onClick={() => {
                              setLoadingEstatusPro(true);
                              setTimeout(() => {
                                const res = activoAction(
                                  dispatch,
                                  clientesState.activo.id,
                                  clientesState.activo.codigos[1].id,
                                  clientesState.activo.codigos[1].activo
                                );
                                setLoadingEstatusPro(!res);
                              }, 500);
                            }}
                          >
                            {clientesState.activo.codigos[1].activo
                              ? 'Bloquear'
                              : 'Activar'}
                          </Tag>
                        )}
                      </div>
                    </>
                  ) : (
                    <>Sin codigos</>
                  )}
                </div>
                <div className="col-2">
                  <strong>Teléfono:</strong>
                </div>
                <div className="col">
                  <Links>
                    <a
                      href={`tel:${clientesState.activo.telefono}`}
                      style={{
                        textDecoration: 'none',
                        color: 'blue',
                      }}
                    >
                      {clientesState.activo.telefono}
                    </a>
                    <a
                      className="wasa"
                      href={`whatsapp://send?abid=${clientesState.activo.telefono}&text=PA LOS PANAS PRODUCCIONES`}
                    >
                      <Iconos h={'1.3vw'} src="../../img/wasa.png" />
                    </a>
                  </Links>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <strong>Correo:</strong>
                </div>
                <div className="col">
                  <Links>
                    <a
                      href={`mailto:${clientesState.activo.correo}`}
                      style={{
                        textDecoration: 'none',
                        color: 'blue',
                      }}
                    >
                      {clientesState.activo.correo}
                    </a>
                  </Links>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <strong>Fecha:</strong>
                </div>
                <div className="col">
                  {dayjs(clientesState.activo.fecha).format(
                    'DD-MM-YYYY HH:mm A'
                  )}
                </div>
              </div>
              <label style={{ fontWeight: 'bold' }}>Descripción:</label>
              <br />
              {clientesState.activo.desc}
            </ModalBody>
            <ModalFooter>
              {clientesState.activo.isLeido ? (
                <></>
              ) : (
                <Button
                  color="success"
                  disabled={loadingCheck}
                  onClick={handleCheck}
                >
                  {loadingCheck ? (
                    <>
                      <Spinner size="sm" />
                      <span> Procesando...</span>
                    </>
                  ) : (
                    'Marcar como leído'
                  )}
                </Button>
              )}
              <Button color="danger" onClick={toggle}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default Listado;
