import React, { useState } from 'react';
import { flipInY } from 'react-animations';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled, { css, keyframes } from 'styled-components';
import { sendMensajeWebClientesService } from '../../../../../services';
import { device } from '../../../../../utils/constants';

const flipXAnimation = keyframes`${flipInY}`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vw;

  @media ${device.mobile} {
    height: auto;
  }
`;

export const BodyForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: black;
  padding: 1vw;

  @media ${device.mobile} {
    height: auto;
    padding: 3vw;
  }

  animation: 1.5s ${flipXAnimation};
`;

export const Info = styled.label`
  font-size: 3vw;
  color: white;
  font-family: 'FuturaBold';
  letter-spacing: 0.13vw;

  @media ${device.mobile} {
    font-size: 7vw;
    margin: 0;
  }
`;

export const FormDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: colum;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 5px;

  @media ${device.mobile} {
    margin: 0;
  }
`;

export const Grupo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
`;

export const SubGrupo = styled.div`
  width: 48.5%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 10px;

  ${(props) =>
    props.textarea &&
    css`
      width: 100%;
    `};

  @media ${device.mobile} {
    margin: 0vw;
    width: 100%;
  }
`;

export const Label = styled.label`
  font-family: 'FuturaLigth';
  font-size: 1vw;
  color: white;
  line-height: 2.4vw;

  @media ${device.mobile} {
    line-height: 7vw;
    font-size: 5vw;
    margin-top: 3vw;
  }
`;

export const Input = styled.input`
  font-size: 1.2vw;
  padding: 0.5vw;
  background: white;
  border: none;
  display: flex;
  padding-left: 25px;
  width: 100%;

  @media ${device.mobile} {
    width: auto;
    font-size: 4vw;
    padding: 1vw;
    padding-left: 30px;
  }
`;

export const TextArea = styled.textarea`
  font-size: 1.2vw;
  padding: 0.5vw;
  background: white;
  border: none;
  display: flex;
  /* width: 33vw;	 */

  @media ${device.mobile} {
    width: auto;
    font-size: 4vw;
  }
`;

export const Boton = styled.div`
  width: 30vw;
  height: 5vw;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
  margin: 2vw;
  font-family: 'FuturaBold';
  font-size: 2.5vw;
  color: white;
  line-height: 2.4vw;
  cursor: pointer;

  &:hover {
    transition: 0.5s;
    font-size: 2.7vw;
    color: #fcee21;
  }

  @media ${device.mobile} {
    width: auto;
    font-size: 6vw;
    height: 15vw;
    width: 70vw;

    &:hover {
      font-size: 6.1vw;
    }
  }
`;

const Form = (props) => {
  const { t } = useTranslation();

  const [nombre, setNombre] = useState();
  const [nombreError, setNombreError] = useState();
  const [apellido, setApellido] = useState();
  const [apellidoError, setApellidoError] = useState();
  const [telefono, setTelefono] = useState();
  const [telefonoError, setTelefonoError] = useState();
  const [correo, setCorreo] = useState();
  const [correoError, setCorreoError] = useState();
  const [desc, setDesc] = useState();

  const [loading, setLoading] = useState();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handlerOnSend = async () => {
    if (loading) return;

    if (nombre == null) {
      setNombreError(true);
      return alert('Falta ingresar el nombre');
    }
    setNombreError(false);
    if (apellido == null) {
      setApellidoError(true);
      return alert('Falta ingresar el apellido');
    }
    setApellidoError(false);
    if (telefono == null) {
      setTelefonoError(true);
      return alert('Falta ingresar el teléfono');
    }
    setTelefonoError(false);
    if (correo == null) {
      setCorreoError(true);
      return alert('Falta ingresar el correo');
    }
    if (!isValidEmail(correo)) {
      setCorreoError(true);
      return alert('Debe ingresar un correo válido');
    }
    setCorreoError(false);
    if (desc == null) return alert('Falta ingresar la descripción');

    setLoading(true);

    try {
      const res = await sendMensajeWebClientesService({
        nombre,
        apellido,
        telefono,
        correo,
        desc,
      });
      alert(res.message);

      if (res.statusCode === 200) {
        setNombre('');
        setApellido('');
        setTelefono('');
        setCorreo('');
        setDesc('');
      }
    } catch (error) {
      alert('Error en procesar su solicitud');
    }
    setLoading(false);
  };

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  const aster = (
    <div
      style={{
        color: 'yellow',
        fontSize: isMobileDevice ? '7vw' : '2vw',
        position: 'absolute',
        top: isMobileDevice ? '55%' : '45%',
      }}
    >
      +
    </div>
  );

  const nombreCom = (
    <SubGrupo>
      <Label style={{ color: nombreError ? '#ff7e7e' : null }}>
        {t('form.nom')}
      </Label>
      <Input
        type="text"
        placeholder=""
        onChange={(e) => setNombre(e.target.value)}
        value={nombre}
      />
      {aster}
    </SubGrupo>
  );

  const apellidoCom = (
    <SubGrupo>
      <Label style={{ color: apellidoError ? '#ff7e7e' : null }}>
        {t('form.ape')}
      </Label>
      <Input
        type="text"
        placeholder=""
        onChange={(e) => setApellido(e.target.value)}
        value={apellido}
      />
      {aster}
    </SubGrupo>
  );

  const phoneCom = (
    <SubGrupo>
      <Label style={{ color: telefonoError ? '#ff7e7e' : null }}>
        {t('form.phone')}
      </Label>
      <Input
        type="text"
        placeholder=""
        onChange={(e) => setTelefono(e.target.value)}
        value={telefono}
      />
      {aster}
    </SubGrupo>
  );

  const emailCom = (
    <SubGrupo>
      <Label style={{ color: correoError ? '#ff7e7e' : null }}>
        {t('form.email')}
      </Label>
      <Input
        type="text"
        placeholder=""
        onChange={(e) => setCorreo(e.target.value)}
        value={correo}
      />
      {aster}
    </SubGrupo>
  );

  const descCom = (
    <SubGrupo textarea>
      <Label>{t('form.desc')}</Label>
      <TextArea
        name="w3review"
        rows="3"
        onChange={(e) => setDesc(e.target.value)}
        value={desc}
      ></TextArea>
    </SubGrupo>
  );

  return (
    <Container>
      <BodyForm>
        <Info>{props.lacaja ? t('form.title1') : t('form.title2')}</Info>
        <FormDiv>
          {isMobileDevice ? (
            <>
              {nombreCom}
              {apellidoCom}
              {phoneCom}
              {emailCom}
              {descCom}
            </>
          ) : (
            <>
              <Grupo>
                {nombreCom}
                {apellidoCom}
              </Grupo>
              <Grupo>
                {phoneCom}
                {emailCom}
              </Grupo>
              {descCom}
            </>
          )}
        </FormDiv>
      </BodyForm>
      <Boton onClick={handlerOnSend}>
        {' '}
        {loading ? 'Procesando...' : 'PING'}
      </Boton>
    </Container>
  );
};

export default Form;
