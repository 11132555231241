import styled, { css } from "styled-components";
import { device } from "../../../utils/constants";

export const FotoMax = styled.div`
  width: 24vw;
  height: 38vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  margin-right: 4vw;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.mobile} {
    width: 90vw;
    height: 75vh;
    margin-top: 5vw;
  }
`;

export const FotoMaxInfo = styled.div`
  width: 90vw;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
  background-color: black;
  opacity: 0.8;

  table {
    width: 100%;
  }

  .titulo {
    color: white;
    font-family: "FuturaBold";
    font-size: 3.4vw;
    text-align: justify;
  }

  .info {
    color: white;
    font-size: 3.4vw;
    margin-bottom: 0.5vw;
    margin-left: 1vw;
    text-align: justify;
  }

  .infonota {
    color: white;
    font-size: 3.4vw;
    margin-bottom: 1vw;
    text-align: justify;
  }

  .bodytecto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .textoscrol {
    overflow: auto;
    height: 80%;
    padding-right: 2vw;
  }
`;

export const BodyFotoMin = styled.div`
  height: 40vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-right: 2.5vw;

  @media ${device.mobile} {
    width: 90%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 5vw;
  }
`;

export const FotoMin = styled.div`
  width: 5vw;
  height: 7vw;
  background-color: white;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;

  ${(props) =>
    props.activo &&
    css`
      width: 7vw;
      height: 9vw;
    `};

  &:hover {
    box-shadow: 0vw 0.2vw 10px #fcee21;
  }

  @media ${device.mobile} {
    width: 24vw;
    height: 35vw;

    ${(props) =>
      props.activo &&
      css`
        width: 28vw;
        height: 39vw;
      `};
  }
`;

export const BodyTexto = styled.div`
  width: 35vw;
  height: 35vw;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;

  .bodyTabsContent {
    height: 40vw;
  }

  .titulo {
    color: white;
    font-family: "FuturaBold";
    font-size: 0.9vw;
    text-align: justify;
  }
  .info {
    color: white;
    font-size: 1.1vw;
    margin-bottom: 0.5vw;
    margin-left: 1vw;
    text-align: justify;
  }

  .infonota {
    color: white;
    font-size: 0.9vw;
    margin-bottom: 1vw;
    text-align: justify;
  }

  .bodytecto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .textoscrol {
    overflow: auto;
    height: 25vw;
    padding-right: 2vw;
  }
`;

export const TituloTexto = styled.label`
  width: 100%;
  font-size: 2vw;
  color: transparent;
  font-family: "FuturaBold";
  letter-spacing: 0.2vw;
  color: white;
  text-align: center;
  margin-bottom: 1.5vw;
  /* -webkit-text-stroke: 1px white; */

  @media ${device.mobile} {
    font-size: 5vw;
  }
`;

export const Descripcion = styled.label`
  font-size: 1.1vw;
  color: transparent;
  font-family: "FuturaRegular";
  color: white;
  text-align: justify;
  overflow: hidden;
  line-height: 1.6vw;

  @media ${device.mobile} {
    font-size: 3.4vw;
    line-height: 5vw;
  }
`;

export const BotonSiguiente = styled.div`
  width: ${({ verMovie }) => (verMovie ? "16vw" : "10vw")};
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1vw;
  flex-direction: column;
  /* margin-right: 2vw; */
  font-family: "FuturaMedium";
  font-weight: ${({ fs }) => (fs ? "bold" : "normal")};
  font-size: ${({ fs }) => fs ?? "1.2vw"};
  color: ${({ verMovie }) => (verMovie ? "white" : "black")};
  /* background-color: #FCEE21; */
  cursor: pointer;

  ${(props) =>
    props.fondo &&
    css`
      width: auto;
      font-family: "FuturaLigth";
      background-color: black;
      color: white;
      padding: 0vw 0.5vw;

      &:hover {
        background-color: #fcee21;
        color: black;
      }
    `};

  &:hover {
    background-color: #fcee21;
    color: black;
  }

  @media ${device.mobile} {
    width: ${({ verMovie }) => (verMovie ? "60vw" : "45vw")};
    height: 13vw;
    font-size: ${({ verMovie }) => (verMovie ? "5vw" : "6vw")};
    margin: 5vw 0vw;

    ${(props) =>
      props.fondo &&
      css`
        width: 50vw;
        height: 5vw;
        color: white;
        font-size: 4vw;

        &:hover {
          background-color: black;
          color: #fcee21;
        }
      `};
  }
`;

export const BotonSiguienteFlecha = styled.div`
  width: 10vw;
  text-align: center;
  line-height: 1vw;
  flex-direction: column;
  font-family: "FuturaMedium";
  font-weight: ${({ fs }) => (fs ? "bold" : "normal")};
  font-size: ${({ fs }) => fs ?? "1.2vw"};
  color: ${({ fs }) => (fs ? " #FCEE21" : "black")};
  background-color: #fcee21;
  cursor: pointer;
  padding: 1vw;
  margin-top: 10px;

  ${(props) =>
    props.fondo &&
    css`
      width: auto;
      font-family: "FuturaLigth";
      background-color: black;
      color: white;
      ${"" /* padding: 0vw 0.5vw; */}

      &:hover {
        color: #fcee21;
      }
    `};

  label {
    color: #fcee21;
  }

  &:hover {
    label {
      color: white;
    }
  }

  @media ${device.mobile} {
    width: 45vw;
    height: 13vw;
    font-size: 6vw;
    margin: 5vw 0vw;

    ${(props) =>
      props.fondo &&
      css`
        width: 50vw;
        height: 5vw;
        color: white;
        font-size: 4vw;

        &:hover {
          background-color: black;
          color: #fcee21;
        }
      `};
  }
`;

export const AlignB = styled.div`
  width: 35vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1.5vw;
`;

export const SectionForm = styled.div`
  width: 35vw;
  height: 55vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: red; */

  .formlacaja {
    width: 50vw;
  }

  @media ${device.mobile} {
    width: 90vw;
    height: 100vh;
  }
`;

export const SectionFooter = styled.div`
  /* width: 35vw;
    height: 45vw ;
	display: flex;
    align-items: center;
    flex-direction: column;
    /* background-color: red; */

  .formlacaja {
    width: 50vw;
  }
  */ @media ${device.mobile} {
    width: 90vw;
  }
`;

export const BodyTabs = styled.div`
  width: 100%;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 2vw;

  .formlacaja {
    width: 50vw;
  }
`;

export const BotonTabs = styled.div`
  height: 2.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "FuturaLigth";
  font-size: 1.1vw;
  color: white;
  cursor: pointer;

  ${(props) =>
    props.activo &&
    css`
      color: #fcee21;
      font-size: 1.1vw;
      font-family: "FuturaMedium";
    `};

  &:hover {
    /* background-color: ${(props) => (props.activo ? "black" : "#FCEE21")}; */
    font-size: 1.2vw;
    color: #fcee21;
  }
`;
