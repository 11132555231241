import React from 'react';
import styled from 'styled-components';
import Typed from 'typed.js';
import { HEADER } from '../../../../common/const';
import { device } from '../../../../utils/constants';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  // background-image: url("./img/plp06.png");
  // background-repeat: no-repeat;
  // background-size: cover;

  span {
    font-family: 'FuturaBold';
    color: white;
    font-size: 7vw;
  }

  @media ${device.mobile} {
    background-size: cover;
    .type-wrap {
      span {
        font-size: 10vw;
      }
    }
  }
`;

const Header = ({ t }) => {
  const el = React.useRef(null);
  const typed = React.useRef(null);
  React.useEffect(() => {
    const options = {
      strings: [
        t('publicidad.cine'),
        t('publicidad.publicidad'),
        t('publicidad.propaganda'),
      ],
      typeSpeed: 140,
      backSpeed: 140,
      loop: true,
    };
    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container id={HEADER}>
        <div className="type-wrap">
          <span style={{ whiteSpace: 'pre' }} ref={el} />
        </div>
      </Container>
    </>
  );
};

export default Header;
