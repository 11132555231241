import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SET_IS_CODE_CONTACTO } from '../../../store/types';
import LaCaja from '../landing-page/lacaja/lacaja';
import LayoutNosotros from '../nosotros/layoutNosotros';

const PageBlog = () => {
  const dispatch = useDispatch();
  const layoutState = useSelector((state) => state.layout);

  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    setTimeout(() => {
      dispatch({
        type: SET_IS_CODE_CONTACTO,
        payload: false,
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutNosotros
      title={t('footer.contact')}
      subTitle="SÍGUENOS Y TE SEGUIMOS"
      isActiveMenu={5}
    >
      <LaCaja codes={layoutState.isCodeContacto} />
    </LayoutNosotros>
  );
};

export default PageBlog;
