import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { history } from "../helpers";
import { WebPage } from "../pages/web";
import { Login } from "../pages/admin/auth";
import { Sidebar } from "../pages/admin/layout";
import { Inicio } from "../pages/admin/inicio";
import { Dashboard } from "../pages/admin/dashboard";
import { Clientes } from "../pages/admin/clientes";
import PageCine from "../pages/web/cine";
import PagePropaganda from "../pages/web/propaganda";
import PagePublicidad from "../pages/web/publicidad";
import PageNosotros from "../pages/web/nosotros";
import PageContacto from "../pages/web/contacto";
import PageServicios from "../pages/web/servicios";
import NewsLetterPage from "../pages/web/newsletter";
import { Newsletter } from "../pages/admin/newsletter";
import { Suscripciones } from "../pages/admin/suscripciones";
import * as ROUTES from "./routes";

import GobiernoPage from "../pages/web/propaganda/GobiernoPage";
import MudPage from "../pages/web/propaganda/MudPage";
import PlanPage from "../pages/web/propaganda/PlanPage";
import SpotsPage from "../pages/web/spots";
import { MIS_VIDEOS } from "../common/const";

const Routers = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={ROUTES.LANDING_PAGE} exact component={WebPage} />
        <Route path={ROUTES.LOGIN} exact component={Login} />
        {/* <Route path={ROUTES.REGISTER} exact component={Register} /> */}
        <Route path={ROUTES.CINE} exact component={PageCine} />
        <Route path={ROUTES.PUBLICIDAD} exact component={PagePublicidad} />
        <Route path={ROUTES.PROPAGANDA} exact component={PagePropaganda} />
        {/* <Route path={ROUTES.BLOG} exact component={PageBlog} /> */}
        <Route path={ROUTES.NOSOTROS} exact component={PageNosotros} />
        <Route path={ROUTES.NEWSLETTER} exact component={NewsLetterPage} />
        <Route path={ROUTES.APLICA} exact component={PageContacto} />
        <Route path={ROUTES.SERVICIOS} exact component={PageServicios} />
        <PrivateVideoPage path={ROUTES.GOBIERNO}>
          <GobiernoPage />
        </PrivateVideoPage>
        <PrivateVideoPage path={ROUTES.MUD}>
          <MudPage />
        </PrivateVideoPage>
        <PrivateVideoPage path={ROUTES.PLAN}>
          <PlanPage />
        </PrivateVideoPage>
        <PrivateVideoPage path={ROUTES.SPOTS}>
          <SpotsPage />
        </PrivateVideoPage>
        <PrivatePage path={ROUTES.ADMIN} component={Inicio}>
          <Sidebar>
            <Switch>
              <Route exact path={ROUTES.ADMIN}>
                <Dashboard />
              </Route>
              <PrivatePage path="/admin/dashboard">
                <Dashboard />
              </PrivatePage>
              <PrivatePage path="/admin/clientes">
                <Clientes />
              </PrivatePage>
              <PrivatePage path="/admin/newsletter">
                <Newsletter />
              </PrivatePage>
              <PrivatePage path="/admin/suscripciones">
                <Suscripciones />
              </PrivatePage>
              {/* <PrivatePage path="/admin/peliculas">
								<Peliculas />
							</PrivatePage>
							<PrivatePage path="/admin/codigos">
								<Codigos />
							</PrivatePage> */}
              {/* <PrivatePage path="/admin/blog">
                <Blog />
              </PrivatePage> */}
            </Switch>
          </Sidebar>
        </PrivatePage>
      </Switch>
    </Router>
  );
};

const validarToken = () => {
  const token = localStorage.getItem("PLPtoken");
  let isValid = false;
  try {
    isValid = token && token !== "";
  } catch (error) {
    return false;
  }
  return isValid;
};

const PrivatePage = ({ children, ...props }) => {
  let isAuthenticated = validarToken();

  return isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={ROUTES.LOGIN} />
  );
};

const PrivateVideoPage = ({ children, ...props }) => {
  const x = localStorage.getItem(MIS_VIDEOS);

  let isAuthenticated = x === "true";

  return isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={ROUTES.PROPAGANDA} />
  );
};

export default Routers;
