import React, { useState } from 'react';
import { FaBloggerB, FaUserEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { logoutAction } from '../../../store/actions';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;
const Body = styled.div`
  /* flex: 1; */
  /* height: 100vh; */
  padding: 0.6vw;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  /* background-color: green; */
`;

const Logo = styled.img`
  width: 8.5vw;
`;

const Sidebar = ({ children }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [isModal, setIsModal] = useState();

  const handleLogout = async () => {
    await logoutAction({ dispatch, history });
  };

  const toggle = () => setIsModal(false);

  return (
    <Container>
      <ProSidebar collapsed={false} width={200}>
        <SidebarHeader>
          <center>
            <Logo src="/img/logo.png" />
          </center>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            {/* <MenuItem icon={<FaHome />}>
                            Dashboard <Link to="/admin/dashboard" />
                        </MenuItem> */}
            <MenuItem icon={<FaUserEdit />}>
              Clientes <Link to="/admin/clientes" />
            </MenuItem>
            {/* <MenuItem icon={<BsFilm />}>
                            Videos <Link to="/admin/peliculas" />
                        </MenuItem>
                        <MenuItem icon={<BiCameraMovie />}>
                            Códigos <Link to="/admin/codigos" />
                        </MenuItem> */}
            <MenuItem icon={<FaBloggerB />}>
              Newsletter <Link to="/admin/newsletter" />
            </MenuItem>
            <MenuItem icon={<FaBloggerB />}>
              Suscripciones <Link to="/admin/suscripciones" />
            </MenuItem>
            {/* <SubMenu title="Components" icon={<FaHeart />}>
                                        <MenuItem>Component 1</MenuItem>
                                        <MenuItem>Component 2</MenuItem>
                                    </SubMenu> */}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square" onClick={() => setIsModal(true)}>
            <MenuItem>Cerrar Sesion</MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
      <Body>{children}</Body>
      <Modal isOpen={isModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Cerrar Sesión</ModalHeader>
        <ModalBody>¿Seguro que deseas cerrar sesión?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleLogout}>
            Cerrar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default Sidebar;
