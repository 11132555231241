import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-item: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: red;
`;

export const Inicio = () => {
  return (
    <Container>
      <h1>Inicio</h1>
    </Container>
  );
};

// export default Inicio;
