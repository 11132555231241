import React, { useEffect, useState } from 'react';
import { zoomIn } from 'react-animations';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled, { keyframes } from 'styled-components';
import { device } from '../../../../utils/constants';
import { Form } from './components';
import FormCode from './components/form_codigo';

const slideRightAnimation = keyframes`${zoomIn}`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  // background-image: url("./img/imagen2.jpg");
  // background-repeat: no-repeat;
  // background-size: 100vw 35vw;
  // background-position-y: bottom;
  padding: 8vw 10vw;
  position: relative;

  @media ${device.mobile} {
    background-size: 150vw 80vw;
    min-height: 100vh;
  }
`;

const Caja = styled.div`
  width: 35vw;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const Leyenda = styled.div`
  width: 25vw;
  position: fixed;
  top: 5%;
  animation: 2s ${slideRightAnimation};
  right: 37%;
  z-index: 999;
  background-color: white;
`;

const LaCaja = ({ lacaja, codes, isRoute, isPropaganda }) => {
  const [isCode, setIsCode] = useState(false);
  const layoutState = useSelector((state) => state.layout);
  const isMobileDevice = useMediaQuery({ query: device.mobile });

  useEffect(() => {
    setIsCode(codes ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container id="lacaja">
        <Caja
          onDoubleClick={() => {
            setIsCode(!isCode);
          }}
          // onMouseEnter={() => {
          //     dispatch({
          //         type: SET_IS_LEYENDA,
          //         payload: true
          //     })
          // }}
          // onMouseLeave={() => {
          //     dispatch({
          //         type: SET_IS_LEYENDA,
          //         payload: false
          //     })
          // }}
        >
          {isCode ? (
            <FormCode
              lacaja={lacaja ?? false}
              setIsCode={setIsCode}
              isRoute={isRoute ?? true}
              isPropaganda={isPropaganda}
            />
          ) : (
            <Form lacaja={lacaja ?? false} />
          )}
        </Caja>
        {layoutState.isLeyenda && !isMobileDevice ? (
          <Leyenda>
            <img alt="lacaja" width="100%" src="../../img/imgCaleta.png" />
            {/* <label>La caleta (s):</label> Un espacio al que puede entrar la gente en la que confiamos. Vean más de cerca lo que hacemos. */}
          </Leyenda>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default LaCaja;
