import React, { useState } from 'react';
import { slideInDown } from 'react-animations';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import styled, { css, keyframes } from 'styled-components';
import { sendMensajeWebSuscripcionesService } from '../../../services';
import { device } from '../../../utils/constants';

const flipXAnimation = keyframes`${slideInDown}`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   height: 40vw;

  @media ${device.mobile} {
    height: auto;
  }
`;

export const BodyForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: black;
  padding: 1vw 2.5vw;

  @media ${device.mobile} {
    height: auto;
    padding: 3vw;
  }

  animation: 2s ${flipXAnimation};
`;

export const Info = styled.label`
  font-size: 3vw;
  color: white;
  font-family: 'FuturaBold';
  letter-spacing: 0.13vw;

  @media ${device.mobile} {
    font-size: 7vw;
    margin: 0;
  }
`;

export const FormDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: colum;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 5px;

  @media ${device.mobile} {
    margin: 0;
  }
`;

export const Grupo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
`;

export const SubGrupo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 10px;

  ${(props) =>
    props.textarea &&
    css`
      width: 100%;
    `};

  @media ${device.mobile} {
    margin: 0vw;
    width: 100%;
  }
`;

export const Label = styled.label`
  font-family: 'FuturaLigth';
  font-size: 1vw;
  color: white;
  line-height: 2.4vw;

  @media ${device.mobile} {
    line-height: 7vw;
    font-size: 5vw;
    margin-top: 3vw;
  }
`;

export const Input = styled.input`
  font-size: 1.2vw;
  padding: 0.5vw;
  background: white;
  border: none;
  display: flex;
  padding-left: 25px;
  width: 100%;

  @media ${device.mobile} {
    width: auto;
    font-size: 4vw;
    padding: 1vw;
    padding-left: 30px;
  }
`;

export const TextArea = styled.textarea`
  font-size: 1.2vw;
  padding: 0.5vw;
  background: white;
  border: none;
  display: flex;
  /* width: 33vw;	 */

  @media ${device.mobile} {
    width: auto;
    font-size: 4vw;
  }
`;

export const Boton = styled.div`
  width: 30vw;
  height: 5vw;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
  margin: 2vw;
  font-family: 'FuturaBold';
  font-size: 2.5vw;
  color: white;
  line-height: 2.4vw;
  cursor: pointer;

  &:hover {
    transition: 0.5s;
    font-size: 2.7vw;
    color: #fcee21;
  }

  @media ${device.mobile} {
    width: auto;
    font-size: 6vw;
    height: 15vw;
    width: 70vw;

    &:hover {
      font-size: 6.1vw;
    }
  }
`;

const FormNewsletter = ({ close }) => {
  const { t } = useTranslation();

  const [fullname, setFullname] = useState();
  const [fullnameError, setFullnameError] = useState();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();

  const [loading, setLoading] = useState();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const onSubmitHandler = async () => {
    if (loading) return;

    if (fullname == null) {
      setFullnameError(true);
      return alert('Falta ingresar el Nombre y Apellido');
    }

    if (email == null) {
      setEmailError(true);
      return alert('Falta ingresar el email');
    }

    if (!isValidEmail(email)) {
      setEmailError(true);
      return alert('Debe ingresar un email válido');
    }

    setEmailError(false);

    setLoading(true);

    try {
      const res = await sendMensajeWebSuscripcionesService({
        fullname,
        email,
      });
      alert(res.message);

      if (res.statusCode === 200) {
        setFullname('');
        setEmail('');
        close();
      }
    } catch (error) {
      alert('Error en procesar su solicitud');
    }
    setLoading(false);
  };

  const fullnameCom = (
    <SubGrupo>
      <Label style={{ color: fullnameError ? '#ff7e7e' : null }}>
        {t('form.fullname')}
      </Label>
      <Input
        type="text"
        placeholder=""
        onChange={(e) => setFullname(e.target.value)}
        value={fullname}
      />
    </SubGrupo>
  );

  const emailCom = (
    <SubGrupo>
      <Label style={{ color: emailError ? '#ff7e7e' : null }}>
        {t('form.email')}
      </Label>
      <Input
        type="text"
        placeholder=""
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
    </SubGrupo>
  );

  return (
    <Container>
      <BodyForm>
        <Info>{t('form.newsletter')}</Info>
        <FormDiv>
          {
            <>
              {fullnameCom}
              {emailCom}
            </>
          }
        </FormDiv>
      </BodyForm>
      <Boton onClick={onSubmitHandler}>
        {' '}
        {loading ? 'Procesando...' : t('form.suscribete')}
      </Boton>
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'white',
          }}
          onClick={() => {
            close();
          }}
        >
          X
        </Button>
      </div>
    </Container>
  );
};

export default FormNewsletter;
