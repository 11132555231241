import React from "react";
import styled from "styled-components";
import { Botones, Contacto, Suscripcion } from "./components";
import { device } from "../../../../utils/constants";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: black;
  flex-direction: row;
  padding: 5vw 10vw;
  z-index: 200;

  @media ${device.mobile} {
    padding: 15vw 10vw;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

const BodyImg = styled.div`
  width: 20vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;

  @media ${device.mobile} {
    width: 1vw;
  }
`;

const BodyBotones = styled.div`
  width: ${({ wp }) => wp ?? "16vw"};
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: yellow; */
  flex-direction: row;

  @media ${device.mobile} {
    width: 40vw;
  }
`;

const Logo = styled.img`
  width: 9vw;

  @media ${device.mobile} {
    width: 20vw;
  }
`;

const Footer = ({ isHome, srcImg }) => {
  const { t } = useTranslation();

  const isMobileDevice = useMediaQuery({ query: device.mobile });
  const logo = isHome ? (
    <Link to={"/"}>
      <Logo src={`${srcImg ?? "img"}/logo.png`} style={{ cursor: "pointer" }} />
    </Link>
  ) : (
    <Logo src={`${srcImg ?? "img"}/logo.png`} />
  );

  return isMobileDevice ? (
    <Container id="contacto">
      <BodyImg data-aos="fade-down" data-aos-duration="1000">
        {logo}
      </BodyImg>
      <BodyBotones
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-delay="600"
      >
        <Suscripcion t={t} srcImg={srcImg} />
      </BodyBotones>
      <BodyBotones
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <Botones t={t} />
      </BodyBotones>
      <BodyBotones
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <Contacto t={t} />
      </BodyBotones>
    </Container>
  ) : (
    <Container id="contacto">
      <BodyImg data-aos="fade-down" data-aos-duration="1000">
        {logo}
      </BodyImg>
      <BodyBotones
        wp="14vw"
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <Botones t={t} />
      </BodyBotones>
      <BodyBotones
        wp="18vw"
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <Contacto t={t} />
      </BodyBotones>
      <BodyBotones
        wp="20vw"
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-delay="600"
      >
        <Suscripcion t={t} srcImg={srcImg} />
      </BodyBotones>
    </Container>
  );
};

export default Footer;
