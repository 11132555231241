import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../utils/constants';
import { Section } from './components';

const Container = styled.div`
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: black;
  flex-direction: column;
  margin-top: 5em;
  margin-bottom: 2em;
  padding: 3vw 10vw 3vw 10vw;

  @media ${device.mobile} {
    padding: 3em 0 0 0;
    margin-top: 2em;
  }
`;

const Concepto = (props) => {
  return (
    <>
      <Container id="concepto">
        <Section />
      </Container>
    </>
  );
};

export default Concepto;
