import styled from 'styled-components';
import { device } from '../../../../../utils/constants';

export const ContenidoDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: white;
  flex-direction: column;
`;

export const Somos = styled.label`
  font-family: 'FuturaRegular';
  font-size: 3vw;
  color: #fcee21;
  line-height: 40px;

  @media ${device.mobile} {
    font-size: 1.8em;
    line-height: 0.8em;
  }
`;

export const CasaProdcDiv = styled.div`
  display: flex;
  //   align-items: flex-start;
  //   justify-content: center;
  color: white;
  flex-direction: row;
  line-height: 30px;

  @media ${device.mobile} {
    line-height: 1em;
    padding-right: 15px;
  }
`;

export const Casa = styled.label`
  font-size: ${({ size }) => size ?? '3vw'};
  color: ${({ color }) => color ?? 'white'};
  font-family: 'FuturaBold';
  letter-spacing: 0.13vw;

  @media ${device.mobile} {
    font-size: ${({ sizeMobile }) => sizeMobile ?? '7vw'};
  }
`;

export const Productora = styled.label`
  font-size: ${({ size }) => size ?? '3vw'};
  color: transparent;
  font-family: 'FuturaBold';
  letter-spacing: 0.2vw;
  -webkit-text-stroke: 1px ${({ color }) => color ?? 'white'};

  @media ${device.mobile} {
    font-size: ${({ sizeMobile }) => sizeMobile ?? '7vw'};
  }
`;

export const SignoLetrasDiv = styled.div`
  width: 55vw !important;
  height: 21vw !important;
  // background-color: red;
  position: relative;

  @media ${device.mobile} {
    width: 90vw !important;
    height: 35vh !important;
  }
`;

export const Signo = styled.label`
  font-size: 10vw;
  color: transparent;
  font-family: 'FuturaBold';
  color: #fcee21;
  position: absolute;
  left: 30%;
  bottom: 23%;
  line-height: 0px;

  @media ${device.mobile} {
    font-size: 5em;
    margin: 0 0 12vw 8vw;
    // line-height: 15vw;
  }
`;

export const LetrasDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  //   justify-content: center;
  //   color: white;
  //   height: 7vw;
  //   /* background-color: grey */

  @media ${device.mobile} {
    padding-left: 15px;
  }
`;

export const Texto = styled.div`
  font-size: 1.6vw;
  color: transparent;
  font-family: 'FuturaRegular';
  color: white;
  margin: 5vw 0px 0 0px;

  @media ${device.mobile} {
    font-size: 4vw;
  }
`;

export const Button = styled.a`
  font-size: 1vw;
  height: 1.5vw;
  color: transparent;
  font-family: 'FuturaBold';
  color: #fcee21 !important;
  margin: 0.5vw 0px 0 0px;
  border-bottom: 1px solid #fcee21;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    font-size: 1.1vw !important;
    color: #fcee21 !important;
  }

  @media ${device.mobile} {
    font-size: 3vw;
    height: 4vw;

    &:hover {
      font-size: 3.1vw !important;
    }
  }
`;
