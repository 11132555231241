import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../../../utils/constants';
import Card from './card';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media ${device.mobile} {
    flex-direction: column;
    // padding: 0 5vw;
  }
`;
const Caja = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const Section = (props) => {
  const [activo, setActivo] = useState(1);

  const isMobileDevice = useMediaQuery({ query: device.mobile });
  const { t } = useTranslation();

  return (
    <Container>
      <Caja onMouseEnter={() => {}} onMouseLeave={() => {}}>
        <Card
          number={'01'}
          titulo={t('hacemos.card1.title')}
          texto={t('hacemos.card1.text')}
          tag={t('hacemos.card1.tag')}
          activo={activo === 1 || !isMobileDevice}
          click={() => setActivo(1)}
        />
        <Card
          number={'02'}
          titulo={t('hacemos.card2.title')}
          texto={t('hacemos.card2.text')}
          tag={t('hacemos.card2.tag')}
          activo={activo === 2 || !isMobileDevice}
          click={() => setActivo(2)}
        />
        <Card
          number={'03'}
          titulo={t('hacemos.card3.title')}
          texto={t('hacemos.card3.text')}
          tag={t('hacemos.card3.tag')}
          activo={activo === 3 || !isMobileDevice}
          click={() => setActivo(3)}
        />
        <Card
          number={'04'}
          titulo={t('hacemos.card4.title')}
          texto={t('hacemos.card4.text')}
          tag={t('hacemos.card4.tag')}
          activo={activo === 4 || !isMobileDevice}
          click={() => setActivo(4)}
        />
      </Caja>
    </Container>
  );
};

export default Section;
