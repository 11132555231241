import styled from "styled-components";
import { device } from "../../../utils/constants";

export const SectionForm = styled.div`
  width: 35vw;
  height: 45vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: red; */

  .formlacaja {
    width: 50vw;
  }

  @media ${device.mobile} {
    width: 90vw;
  }
`;

export const BodyPlay = styled.div`
  width: 30vw;
  height: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  margin: 0vw 3vw;

  .formlacaja {
    width: 50vw;
  }

  iframe {
    box-sizing: border-box;
    height: 300px;
    /* height: 56.25vw; */
    /* left: 50%; */
    /* min-height: 100%;
        min-width: 100%; */
    /* transform: translate(-50%, -50%); */
    /* position: absolute; */
    /* top: 50%; */
    /* width: 177.77777778vh; */
  }

  @media ${device.mobile} {
    height: 30vh;

    iframe {
      box-sizing: border-box;
    }
  }
`;

export const SectionPublicidad = styled.div`
  width: 100vw;
  height: ${(props) => props.h};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: black; */
  /* margin: ${(props) => props.m} */
`;
