import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../../utils/constants';
import {
  Casa,
  CasaProdcDiv,
  LetrasDiv,
  Productora,
  Signo,
  SignoLetrasDiv,
  Somos,
} from './components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  background-image: url('./img/imagen1.jpg');
  background-repeat: no-repeat;
  background-size: 100vw 35vw;
  background-position-y: bottom;

  @media ${device.mobile} {
    background-size: 120vw 70vw;
  }
`;

const Conocenos = () => {
  const movil = useMediaQuery({ query: device.mobile });

  const agencia = (
    <LetrasDiv>
      <Somos data-aos="fade-up" data-aos-duration="1000">
        SOMOS
      </Somos>
      <Casa data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
        <Productora
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="600"
          style={{ marginRight: '1vw' }}
        >
          AGENCIA
        </Productora>
        DE
      </Casa>
      <Casa
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="900"
        style={{ lineHeight: movil ? '0.5em' : '30px' }}
      >
        COMUNICACIONES
      </Casa>
      <Casa data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1200">
        INTEGRAL
      </Casa>
    </LetrasDiv>
  );

  const casa = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'absolute',
        bottom: 0,
        right: 0,
      }}
    >
      <CasaProdcDiv>
        <Casa data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1200">
          CASA
        </Casa>
        <Productora
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1500"
        >
          PRODUCTORA
        </Productora>
      </CasaProdcDiv>
      <Casa data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1800">
        AUDIOVISUAL
      </Casa>
    </div>
  );

  return (
    <Container id="conocenos">
      <SignoLetrasDiv>
        {agencia}
        <Signo
          data-aos="fade-up-right"
          data-aos-duration="1000"
          data-aos-delay="1200"
        >
          /
        </Signo>
        {casa}
      </SignoLetrasDiv>
    </Container>
  );
};

export default Conocenos;
