import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../../utils/constants';

const Container = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
  padding: 0em 2em;
  margin-bottom: 2em;

  &:hover .something {
    color: #fcee21;
    border-bottom: 1px solid #fcee21;
  }

  @media ${device.mobile} {
    margin-left: 0vw;
    width: 100%;
    flex-direction: column;
  }
`;

const Numero = styled.label`
  width: 100%;
  font-family: 'FuturaRegular';
  font-size: 1.3vw;
  color: white;
  line-height: 2.4vw;
  border-bottom: 1px solid white;
  margin-bottom: 2em;

  @media ${device.mobile} {
    font-size: 7vw;
    line-height: 10vw;
  }
`;

const Texto = styled.label`
  font-family: 'FuturaRegular';
  font-size: 1.1vw;
  color: white;

  @media ${device.mobile} {
    font-size: 5vw;
  }
`;

const Tags = styled.label`
  font-family: 'FuturaLigth';
  font-size: 1.2vw;
  color: #fcee21;
  margin: 1vw 0vw;

  @media ${device.mobile} {
    font-size: 4vw;
  }
`;

const Card = (props) => {
  return (
    <Container>
      <Numero
        data-aos="fade-right"
        data-aos-duration="600"
        data-aos-delay="200"
        className="something"
        onClick={props.click}
      >
        {props.titulo}
      </Numero>
      {!props.activo ? null : (
        <>
          <Texto
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="400"
          >
            {props.texto}
          </Texto>
          <Tags data-aos="fade-up" data-aos-duration="600" data-aos-delay="600">
            {props.tag}
          </Tags>
        </>
      )}
    </Container>
  );
};

export default Card;
