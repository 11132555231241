import axios from "axios";
import * as API from "./api";

// export const getAllPublicoCodigosService = async ({ urlx, page, query }) => {
//     let url = urlx ?? API.codigosPublico

//     if (page && query) {
//         url = `${API.codigosPublico}?query=${JSON.stringify(query)}&page=${page}`
//     } else {
//         if (page) url = `${API.codigosPublico}?page=${page}`;
//         if (query) url = `${API.codigosPublico}?query=${JSON.stringify(query)}`;
//     }

//     try {
//         const res = await axios.get(url);
//         return res
//     } catch (error) {
//         console.log(error);
//     }
// }

export const getAllCodigosService = async (page) => {
  let url = API.codigos;
  if (page) url = `${API.codigos}?page=${page}`;

  try {
    const res = await axios.get(url, {
      headers: await API.Token({ isToken: true }),
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getOneCodigosService = async (id) => {
  const res = await axios.get(`${API.codigos}/${id}`, {
    headers: await API.Token({ isToken: true }),
  });
  return res;
};

export const activoCodigosService = async (id, cliente, status) => {
  const res = await axios.get(
    `${API.codigos}/${id}/activo?p=${status}&c=${cliente}`,
    {
      headers: await API.Token({ isToken: true }),
    },
  );
  return res;
};

export const buscarCodigosService = async (code) => {
  const res = await axios.get(`${API.codigos}/buscar/${code}`, {
    headers: await API.Token({ isToken: true }),
  });
  return res;
};

export const saveCodigosService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.patch(`${API.codigos}/${id}`, data, {
      headers: await API.Token({ isToken: true }),
    });
  } else {
    res = await axios.post(API.codigos, data, {
      headers: await API.Token({ isToken: true }),
    });
  }
  return res;
};

export const saveCodigoPeliculaService = async (idCodigo, idPelicula) => {
  const res = await axios.get(
    `${API.codigos}/${idCodigo}/peliculas/${idPelicula}`,
    {
      headers: await API.Token({ isToken: true }),
    },
  );

  return res;
};

export const deleteCodigoPeliculaService = async (idCodigo, idPelicula) => {
  const res = await axios.delete(
    `${API.codigos}/${idCodigo}/peliculas/${idPelicula}`,
    {
      headers: await API.Token({ isToken: true }),
    },
  );

  return res;
};
