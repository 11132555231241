import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../utils/constants';
import HeaderServicios from './header.servicios';
import BannerServicios from './banner.servicios';
import LayoutNosotros from '../nosotros/layoutNosotros';
import LocationServicios from './location.servicios';
import LogisticasServicios from './logisticas.servicios';
import CastingServicios from './casting.servicios';
import NosotrosServicios from './nosotros.servicios';
import DirectorServicios from './director.servicios';
import FooterServicios from './footer.servicios';
import { useDispatch } from 'react-redux';
import { SET_LANG } from '../../../store/types';

export const RED_COLOR = '#bc2c24';

const SeviciosPages = () => {
  const dispatch = useDispatch();

  const mobile = useMediaQuery({ query: device.mobile });

  useEffect(() => {
    const lang =
      window.localStorage.getItem('i18nextLngPLP') === 'en' ? 'en' : 'es';
    dispatch({
      type: SET_LANG,
      payload: lang,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutNosotros
      notHeader
      footer={false}
      isWhiteLang={false}
      menuBlack={mobile}
      noLogo
    >
      <HeaderServicios />
      <BannerServicios />
      <LocationServicios />
      <LogisticasServicios />
      <CastingServicios />
      <NosotrosServicios />
      <DirectorServicios />
      <FooterServicios />
    </LayoutNosotros>
  );
};

export default SeviciosPages;
