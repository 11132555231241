import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../../../utils/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: Column;
  justify-content: center;
  align-items: flex-end;

  a {
    text-decoration: none !important;
    color: black;
  }

  @media ${device.mobile} {
    align-items: center;
    margin-left: 10vw;
  }
`;

const Botons = styled.div`
  width: 20vw;
  height: 3.5vw;
  display: flex;
  background-color: #fcee21;
  font-family: 'FuturaBold';
  flex-direction: Column;
  justify-content: center;
  align-items: center;
  border-radius: 1.75vw;
  margin-top: 5.4vw;
  cursor: pointer;
  font-size: 1.2vw;

  &:hover {
    background-color: #fff;
  }

  @media ${device.mobile} {
    font-size: 3.3vw;
    width: 50vw;
    height: 9vw;
    border-radius: 4vw;
    margin-top: 10vw;
  }
`;

const BodyIconos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobile} {
    width: auto;
  }
`;

const Iconos = styled.img`
  width: ${({ w }) => w ?? '2.5vw'};
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  @media ${device.mobile} {
    width: ${({ w }) => w ?? '1.5em'};
    margin-right: 5px;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

const Suscripcion = ({ t, srcImg }) => {
  const openInNewTab = (url) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const mobile = useMediaQuery({ query: device.mobile });

  return (
    <Container>
      {/* <Titulo >SUSCRIBETE A <Titulo activo >LA CAJA</Titulo></Titulo>
			<Input type="text" placeholder="EMAIL" /> */}
      <BodyIconos>
        <Iconos
          onClick={() =>
            openInNewTab(
              'https://www.youtube.com/channel/UC3ra91gf1g2RToJ7nwjRG8g'
            )
          }
          src={`${srcImg ?? 'img'}/youtube.png`}
        />
        <Iconos
          onClick={() =>
            openInNewTab(
              'https://www.linkedin.com/company/74562983/admin/feed/posts/'
            )
          }
          src={`${srcImg ?? 'img'}/linkend.png`}
        />
        <Iconos
          onClick={() =>
            openInNewTab(
              'https://instagram.com/palospanasprod?igshid=1t0x76sa705f0'
            )
          }
          src={`${srcImg ?? 'img'}/insta.png`}
        />
        <Iconos
          onClick={() => openInNewTab('https://twitter.com/palospanasprod')}
          src={`${srcImg ?? 'img'}/twitter.png`}
        />

        <Iconos
          w={mobile ? '1.5em' : '2.1vw'}
          onClick={() => openInNewTab('https://www.tiktok.com/@palospanasprod')}
          src={`${srcImg ?? 'img'}/tiktok.png`}
        />
        <Iconos
          w={mobile ? '1.5em' : '2.1vw'}
          onClick={() => openInNewTab('https://vimeo.com/palospanas')}
          src={`${srcImg ?? 'img'}/vimeo.png`}
        />
        <Iconos
          w={mobile ? '2.5em' : '3.2vw'}
          onClick={() =>
            openInNewTab(
              'https://www.imdb.com/search/title/?companies=co0531373'
            )
          }
          src={`${srcImg ?? 'img'}/imdb.png`}
        />
      </BodyIconos>
      <a href="mailto:contacto@palospanas.com">
        <Botons onClick={() => {}}>{t('footer.question')}</Botons>
      </a>
    </Container>
  );
};

export default Suscripcion;
