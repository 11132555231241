import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../utils/constants';

const Container = styled.div`
  width: 420px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  padding: 0em 2em;
  margin-bottom: 2em;

  &:hover .something {
    color: #fcee21;
    border-bottom: 1px solid #fcee21;
  }

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0em 0em;
  }
`;

const CardPerson = ({ name, text, foto }) => {
  const mobile = useMediaQuery({ query: device.mobile });

  return (
    <Container>
      <div
        style={{
          width: mobile ? '50%' : '13vw',
          height: mobile ? '50%' : '13vw',
          borderRadius: mobile ? '25%' : '90px',
          position: 'relative',
          marginTop: '2em',
        }}
      >
        <img
          alt="foto"
          src={`img/${foto}`}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '150px',
          }}
        />
      </div>
      <div
        style={{
          fontFamily: 'FuturaBold',
          backgroundColor: '#fcee21',
          padding: '.2em 0',
          marginTop: '15px',
          width: mobile ? '100%' : '90%',
          textAlign: 'center',
        }}
      >
        {name}
      </div>
      <div
        style={{
          fontFamily: 'FuturaBold',
          color: 'white',
          padding: '3px 0px',
          marginTop: '5px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {text}
      </div>
    </Container>
  );
};

export default CardPerson;
