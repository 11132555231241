import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../utils/constants';
import { Productora } from '../landing-page/conocenos/components';

export const ContenidoDiv = styled.div`
  witdh: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  margin: 0 4vw 4vw 4vw;
  padding: 4vw 5vw 4vw 5vw;
  position: relative;

  @media ${device.mobile} {
    margin: 0 3em 3em 3em;
    align-items: center;
    padding: 2em 1em;
    margin: 0;
    margin-bottom: 1em;
  }
`;

const ImagenText = styled.div`
  display: flex;
  align-items: flex-start;

  @media ${device.mobile} {
    align-items: center;
    justify-content: center;
  }
`;

const InfoText = styled.label`
  font-family: FuturaMedium;
  font-size: 1.2vw;
  color: white;
  font-style: ${({ fontStyle }) => fontStyle ?? 'italic'};
  text-align: justify;
  heigth-line: 1.1em;

  @media ${device.mobile} {
    font-size: 1.1em;
  }
`;

const LabelText = styled.label`
  font-family: FuturaMedium;
  font-size: 1.2vw;
  color: white;
  font-style: ${({ fontStyle }) => fontStyle ?? 'italic'};
  text-align: justify;
  heigth-line: 1.1em;
  margin-top: 2vw;

  @media ${device.mobile} {
    margin-top: 20px;
    font-size: 1.1em;
  }
`;

const Foto = styled.img`
  background-color: transparent;
  width: 13vw;
  heigth-line: 3em;
  vertical-align: text-bottom;
  margin-right: 10px;
  margin-bottom: 5px;

  @media ${device.mobile} {
    width: 200px;
  }
`;

const InfoFotoComponent = ({ select, info }) => {
  useEffect(() => window.scrollTo(0, 0), []);

  const mobile = useMediaQuery({ query: device.mobile });

  const lang = window.localStorage.getItem('i18nextLngPLP');

  const letra = (
    <Productora
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="300"
      size={mobile ? '20vw' : '3.5vw'}
      style={{
        position: mobile ? 'none' : 'absolute',
        right: mobile ? '1.5em' : '4vw',
        top: mobile ? '1.5em' : '4vw',
        textAlign: mobile ? 'center' : 'end',
        lineHeight: mobile ? '1em' : '3.5vw',
        padding: mobile ? '1em 0em' : '0',
      }}
    >
      {info.titleTop}
      <br />
      {info.titleBottom}
    </Productora>
  );

  const footo = (
    <Foto
      color="black"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="300"
      src={info.foto ?? 'img/foto.png'}
    />
  );

  function nota() {
    const Notas = styled.label`
      font-family: FuturaMedium;
      font-size: 1vw;
      color: white;
      font-style: ${({ fontStyle }) => fontStyle ?? 'italic'};
      text-align: center;
      heigth-line: 1.1em;
      margin-top: 3vw;
      padding: 0 1vw;

      @media ${device.mobile} {
        margin-top: 2em;
        padding-bottom: 15px;
        font-size: 0.9em;
      }
    `;

    return (
      (info.notaES || info.notaEN) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            margin: '0 2vw',
          }}
        >
          <img
            alt="nota"
            src="../../img/Asset1.png"
            style={{
              width: mobile ? '1.5em' : '2vw',
              height: mobile ? '1.5em' : '2vw',
              marginTop: mobile ? '1em' : '2vw',
            }}
          />

          {lang === 'en' ? (
            <Notas>{info.notaEN}</Notas>
          ) : (
            <Notas>{info.notaES}</Notas>
          )}
          <img
            alt="nota2"
            src="../../img/Asset2.png"
            style={{
              width: mobile ? '1.5em' : '2vw',
              height: mobile ? '1.5em' : '2vw',
              marginTop: mobile ? '13em' : '6vw',
            }}
          />
        </div>
      )
    );
  }

  return (
    <ContenidoDiv
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="300"
    >
      {mobile && footo}
      {mobile && letra}
      <ImagenText>
        <InfoText
          color="black"
          fontStyle={info.id === 3 ? 'normal' : null}
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          {!mobile && footo}
          {info.textoES[0]}
          <br />
          {nota()}
          {lang === 'en'
            ? info.textoEN.map(
                (el, i) =>
                  i !== 0 && (
                    <LabelText
                      fontStyle={info.id === 3 ? 'normal' : null}
                      key={i}
                    >
                      {el}
                    </LabelText>
                  )
              )
            : info.textoES.map(
                (el, i) =>
                  i !== 0 && (
                    <LabelText
                      fontStyle={info.id === 3 ? 'normal' : null}
                      key={i}
                    >
                      {el}
                    </LabelText>
                  )
              )}
        </InfoText>
      </ImagenText>
      {!mobile && letra}
      <div
        color="black"
        style={{
          fontFamily: 'FuturaRegular',
          marginTop: '3em',
          width: '100%',
          color: 'white',
          display: 'flex',
          flexDirection: mobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label
          style={{
            fontFamily: 'FuturaMedium',
            fontSize: mobile ? '1.1em' : '1.1vw',
          }}
        >
          {lang === 'en' ? info.footerLetfEN : info.footerLetfES}
        </label>
        <div
          style={{
            width: mobile ? '80%' : '30%',
            backgroundColor: 'white',
            height: '2px',
            margin: mobile ? '1em 0' : '0',
          }}
        ></div>
        <label
          style={{
            fontSize: mobile ? '1.1em' : '1.1vw',
            fontFamily: 'FuturaMedium',
            fontStyle: 'italic',
          }}
        >
          {lang === 'en' ? info.footerRigthEN : info.footerRigthES}
          {/* CEO | PRODUCTOR EJECUTIVO */}
        </label>
      </div>
    </ContenidoDiv>
  );
};

export default InfoFotoComponent;
