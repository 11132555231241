import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { device } from '../../../../../utils/constants';

const Container = styled.div`
  width: 100vw;
  height: 22vw;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.right &&
    css`
      align-items: flex-end;
    `};

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  text-decoration: none;

  @media ${device.mobile} {
    height: 50vw;
  }

  a {
    text-decoration: none;
  }
`;

const Titulo = styled.label`
  font-family: 'FuturaBold';
  font-size: 3vw;
  color: white;
  margin: 0px 10vw;

  @media ${device.mobile} {
    font-size: 7vw;
    margin: 0 4vw;
    line-height: 5vw;
  }
`;

const Texto = styled.a`
  font-family: 'FuturaRegular';
  width: fit-content;
  font-size: 1.3vw;
  color: white;
  text-decoration: none;
  margin: 0px 10vw;
  position: relative;
  cursor: pointer;

  &:hover {
    font-size: 1.5vw;
    color: white;
    text-decoration: underline;
  }

  @media ${device.mobile} {
    font-size: 3.5vw;
    margin: 0 4vw;

    &:hover {
      font-size: 3.7vw;
      text-decoration: underline;
    }
  }
`;

const Section = (props) => (
  <Container right={props.right} image={props.image}>
    <Titulo data-aos="zoom-in">{props.titulo}</Titulo>
    <Link to={props.link}>
      <Texto data-aos="zoom-in">{props.texto}</Texto>
    </Link>
  </Container>
);

export default Section;
