import { SET_SECTION_LANDING } from "../types";

const initialState = {
  section: null,
};

export const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SECTION_LANDING:
      return {
        ...state,
        section: action.payload,
      };

    default:
      return state;
  }
};
