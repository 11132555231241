import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../../utils/constants';

const Container = styled.div`
  width: 29vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  label {
    &:hover {
      -webkit-text-stroke: 1px #fcee21;
    }
  }

  @media ${device.mobile} {
  }
`;

const Numero = styled.label`
  font-family: 'FuturaBold';
  font-size: 9vw;
  color: transparent;
  position: relative;
  -webkit-text-stroke: 1px grey;

  @media ${device.mobile} {
    font-size: 21vw;
    margin-right: ${({ nvw }) => nvw ?? '20vw'};
    margin-top: 5vw;
  }
`;

const Texto = styled.div`
  font-family: 'FuturaRegular';
  width: 5vw;
  font-size: 1.4vw;
  color: white;
  position: relative;
  right: ${(props) => props.right};
  margin-top: 3vw;
  display: flex;

  @media ${device.mobile} {
    font-size: 6vw;
    width: 30vw;
    position: absolute;
    line-height: 7vw;
    /* margin-right: 10vw; */
    right: ${({ right }) => right};
  }
`;

const Section = (props) => {
  return (
    <Container>
      <Numero data-aos="fade-up" data-aos-duration="1000" nvw={props.nvw}>
        {props.number}
      </Numero>
      <Texto
        data-aos="fade"
        data-aos-duration="1400"
        nvw={props.nvw}
        right={props.right}
      >
        {props.texto}
      </Texto>
    </Container>
  );
};

export default Section;
