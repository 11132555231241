import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Router from "./router/Router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/store";
import "./i18n";
import "bootstrap/dist/css/bootstrap.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<div>Loading</div>}>
        <Router />
      </Suspense>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));

// https://github.com/azizmashkour/react-i18n/blob/master/src/App.js
