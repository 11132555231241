import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MIS_VIDEOS, MIS_VIDEOS_CINE } from '../../../../../common/const';
import { CINE, PROPAGANDA } from '../../../../../router/routes';
import { buscarCodigosService } from '../../../../../services';
import {
  SET_CODIGOS_ACTIVO,
  SET_CODIGOS_ACTIVO_CINE,
  SET_VIDEO_CINE,
  SET_VIDEO_PROPAGANDA,
} from '../../../../../store/types';
import { device } from '../../../../../utils/constants';
import {
  BodyForm,
  Boton,
  Container,
  FormDiv,
  Info,
  Input,
  SubGrupo,
} from './form';

const Label = styled.label`
  font-family: 'FuturaLigth';
  font-size: 1vw;
  color: white;
  line-height: 2.4vw;

  @media ${device.mobile} {
    line-height: 7vw;
    font-size: 5vw;
    margin-top: 3vw;
  }
`;

const FormCode = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let history = useHistory();
  let location = useLocation();

  const [code, setCode] = useState();
  const [count, setCount] = useState(1);
  const [codeError, setCodeError] = useState();

  const [loading, setLoading] = useState();

  const handlerOnSend = async () => {
    if (loading) return;

    setCodeError(false);
    setLoading(true);

    if (code == null) {
      setCodeError(true);
      return alert('Falta ingresar el código');
    }

    try {
      const res = await buscarCodigosService(code);
      if (res.status === 200) {
        if (!res.data.data.activo) {
          alert('El código se encuentra bloqueado');
          setLoading(false);
          setCode('');
          return;
        }
        if (res.data.data) {
          setCode('');
          setCount(0);

          if (
            (location.pathname === PROPAGANDA &&
              res.data.data.tipoCodigo.id === 1) ||
            (location.pathname === CINE && res.data.data.tipoCodigo.id === 2)
          ) {
            setLoading(false);
            return alert('Este código no es permitido en esta vista');
          }

          if (res.data.data.tipoCodigo.id === 1) {
            localStorage.setItem(MIS_VIDEOS_CINE, true);
            dispatch({
              type: SET_VIDEO_CINE,
              payload: true,
            });
            dispatch({
              type: SET_CODIGOS_ACTIVO_CINE,
              payload: res.data.data,
            });
          }

          if (res.data.data.tipoCodigo.id === 2) {
            localStorage.setItem(MIS_VIDEOS, true);
            dispatch({
              type: SET_VIDEO_PROPAGANDA,
              payload: true,
            });
            dispatch({
              type: SET_CODIGOS_ACTIVO,
              payload: res.data.data,
            });
          }

          if (props.isRoute) {
            if (res.data.data.tipoCodigo.id === 1) {
              history.push(CINE);
            } else {
              history.push(PROPAGANDA);
            }
          }
        } else {
          setCount(count + 1);
          if (count > 1) {
            setCode('');
            setCount(0);
            props.setIsCode(false);
          }
          alert(res.data.message);
        }
      }
    } catch (error) {
      alert(error.message);
      alert('Error en procesar su solicitud');
    }
    setLoading(false);
  };

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  const aster = (
    <div
      style={{
        color: 'yellow',
        fontSize: isMobileDevice ? '7vw' : '2vw',
        position: 'absolute',
        top: isMobileDevice ? '55%' : '45%',
      }}
    >
      +
    </div>
  );

  const codeCom = (
    <SubGrupo textarea>
      <Label style={{ color: codeError ? '#ff7e7e' : null }}>
        {t('form.code')}
      </Label>
      <Input
        type="text"
        placeholder=""
        onChange={(e) => setCode(e.target.value)}
        value={code}
        maxLength={6}
      />
      {aster}
    </SubGrupo>
  );

  return (
    <Container
    // data-aos="fade"
    // data-aos-duration="1000"
    >
      <BodyForm>
        <Info>{props.lacaja ? t('form.title1') : t('form.title2')}</Info>
        <FormDiv>{codeCom}</FormDiv>
      </BodyForm>
      <Boton onClick={handlerOnSend}>
        {' '}
        {loading ? 'Procesando...' : 'PING'}
      </Boton>
    </Container>
  );
};

export default FormCode;
