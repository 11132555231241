import { slideInDown, slideOutLeft } from 'react-animations';
import styled, { css, keyframes } from 'styled-components';
import { device } from '../../../../../utils/constants';

const slideInDownAnimation = keyframes`${slideInDown}`;
const slideOutLeftAnimation = keyframes`${slideOutLeft}`;

const Container = styled.div`
  width: 100vw;
  position: fixed;
  z-index: 990;
  height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: black; */

  @media ${device.mobile} {
    padding: 20px;
    background: black !important;
    width: 40% !important;
    height: 85px;
    ${(props) =>
      !props.open &&
      css`
        opacity: 0 !important;
        width: 0% !important;
        transition-property: width, opacity !important;
        transition-duration: 0.5s, 1s !important;
        animation: 1s ${slideOutLeftAnimation} !important;
      `};

    ${(props) =>
      props.open &&
      css`
        opacity: 1;
        display: block;
      `};
  }

  /*
	@media ${device.desktop} {
		background: transparent;
		opacity: 1;
		padding: 25px;
		width: 15%;
		display: block;
		heigth: 85px;
		background-color: red;
		animation: 1s ${slideInDownAnimation};
	} */
`;

export default Container;
