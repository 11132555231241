import { combineReducers } from "redux";
import { authReducer } from "./auth.reducer";
import { clientesReducer } from "./clientes.reducer";
import { layoutReducer } from "./layout.reducer";
import { peliculasReducer } from "./peliculas.reducer";
import { usersReducer } from "./users.reducer";
import { codigosReducer } from "./codigos.reducer";
import { blogReducer } from "./blog.reducer";
import { newsletterReducer } from "./newsletter.reducer";
import { suscripcionesReducer } from "./suscripciones.reducer";
import { landingReducer } from "./landing.reducer";
import { tiposBoletinReducer } from "./tipos_boletin.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  clientes: clientesReducer,
  layout: layoutReducer,
  users: usersReducer,
  peliculas: peliculasReducer,
  codigos: codigosReducer,
  blog: blogReducer,
  newsletter: newsletterReducer,
  suscripciones: suscripcionesReducer,
  landing: landingReducer,
  tiposBoletin: tiposBoletinReducer,
});

export default rootReducer;
