import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../../../../utils/constants';
import { Section } from './components';

const Container = styled.div`
  width: 100vw;
  height: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  flex-direction: row;
  padding-top: 5vw;

  @media ${device.mobile} {
    height: auto;
    /* background-color: red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 6vw;
  }
`;

const Tags = (props) => {
  const { t } = useTranslation();

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  return (
    <>
      <Container id="tags">
        <Section
          number={'17'}
          texto={t('tags.tag1')}
          right={isMobileDevice ? '10vw' : '30px'}
          nvw={'-5vw'}
        />
        <Section
          number={'-300'}
          texto={t('tags.tag2')}
          right={isMobileDevice ? '10vw' : '15px'}
          nvw={'30vw'}
        />
        <Section
          number={'+25'}
          texto={t('tags.tag3')}
          right={isMobileDevice ? '10vw' : '15px'}
          nvw={'15vw'}
        />
      </Container>
    </>
  );
};

export default Tags;
