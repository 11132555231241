import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../utils/constants';
import Concepto from '../landing-page/concepto/concepto';
import CardPerson from './card-person';
import LayoutNosotros from './layoutNosotros';

const PageBlog = () => {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  const isMobileDevice = useMediaQuery({ query: device.mobile });

  return (
    <LayoutNosotros title={t('menu.nosotro')} isActiveMenu={2}>
      <Concepto />
      <div
        style={{
          marginBottom: '3em',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          alt="organ"
          src="img/organ.png"
          style={{
            width: '90%',
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: 'black',
          padding: '1em 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: isMobileDevice ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            width: '100%',
          }}
        >
          <CardPerson
            name="IGNACIO CASTILLO COTTIN"
            text="CEO | PRODUCTOR EJECUTIVO"
            foto="IGNACIO.jpg"
          />
          <CardPerson
            name="JESSICA JALFÓN"
            text="PRODUCTORA GENERAL"
            foto="JESSICA.jpg"
          />
          <CardPerson
            name="VALENTINA SPERANDIO"
            text="VP DE MARCA"
            foto="VALENTINA.jpg"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: isMobileDevice ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            width: '100%',
          }}
        >
          <CardPerson
            name="SASHA ACKERMAN"
            text="DIRECTORA DE MEDIOS, PRENSA Y PR"
            foto="SASHA.jpg"
          />
          <CardPerson
            name="VERÓNICA GAZZARA"
            text="JEFA DE PRODUCCIÓN"
            foto="VERONICA.jpg"
          />
        </div>
      </div>
    </LayoutNosotros>
  );
};

export default PageBlog;
