import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PUBLICIDAD } from "../../../../common/const";
import * as ROUTES from "../../../../router/routes";
import { Section } from "./components";

const Container = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  flex-direction: column;
`;

const Publicidad = () => {
  const { t } = useTranslation();

  return (
    <Container id={PUBLICIDAD}>
      <Section
        titulo={`${t("publicidad.cine")} /`}
        texto={`${t("publicidad.cineText")} >`}
        image={"img/cine.jpeg"}
        link={ROUTES.CINE}
      />
      <Section
        titulo={`/ ${t("publicidad.publicidad")}`}
        right={true}
        texto={`${t("publicidad.pubText")} >`}
        image={"img/publicidad.jpg"}
        link={ROUTES.PUBLICIDAD}
      />
      <Section
        titulo={`/ ${t("publicidad.propaganda")}`}
        texto={`${t("publicidad.proText")} >`}
        image={"img/stock.jpg"}
        link={ROUTES.PROPAGANDA}
      />
    </Container>
  );
};

export default Publicidad;
