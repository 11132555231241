import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import LayoutNosotros from '../nosotros/layoutNosotros';
import CardVideosComponent from '../propaganda/CardVideos';
import { videosGob } from '../propaganda/GobiernoPage';
import { videosMud } from '../propaganda/MudPage';
import { videosPlan } from '../propaganda/PlanPage';
import { SectionPublicidad } from './components';

const SpotsPage = () => {
  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  // let history = useHistory();

  const toggle = () => setModal(!modal);

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  return (
    <LayoutNosotros
      title={'SPOTS'}
      subTitle={t('publicidad.propaganda')}
      srcImg={'../img'}
      logo="../img/logo.png"
    >
      <SectionPublicidad>
        {videosMud.map((el, i) => {
          return (
            <CardVideosComponent
              img={el.img}
              onClick={() => {
                setModal(true);
                setVideoLink(el.url);
              }}
            />
          );
        })}
        {videosGob.map((el, i) => {
          return (
            <CardVideosComponent
              img={el.img}
              onClick={() => {
                setModal(true);
                setVideoLink(el.url);
              }}
            />
          );
        })}
        {videosPlan.map((el, i) => {
          return (
            <CardVideosComponent
              img={el.img}
              onClick={() => {
                setModal(true);
                setVideoLink(el.url);
              }}
            />
          );
        })}
      </SectionPublicidad>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="lg"
        className="modal-blog-video"
      >
        <center>
          <figure
            style={{
              left: 0,
              width: '100%',
              height: 0,
              position: 'relative',
              paddingBottom: '56.25%',
              marginBlockEnd: 0,
              marginBlockStart: 0,
              marginInlineStart: 0,
              marginInlineEnd: 0,
            }}
          >
            <iframe
              title="video"
              id="pv_mMawGz38"
              src={videoLink}
              scrolling="no"
              style={{
                border: 0,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
                overflow: 'hidden',
              }}
              allowFullScreen="false"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            />
          </figure>
        </center>
      </Modal>
    </LayoutNosotros>
  );
};

export default SpotsPage;
