import styled, { css } from 'styled-components';

const Button = styled.a`
  font-size: 1.3vw;
  font-family: 'FuturaMedium';
  color: white;
  cursor: pointer;
  width: 10vw;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: row;  */
  padding: 1vw 0;
  margin: 0px 10px;
  text-decoration: none !important;

  &:hover {
    transition: 0.5s;
    font-size: 1.4vw;
    color: #fcee21;
    transition: 0.5s;
  }

  ${(props) =>
    props.isHover &&
    css`
      &:hover {
        background-color: #fcee21;
        color: black;
        font-size: 1.4vw;
      }
    `};

  ${(props) =>
    !props.siempre &&
    props.activo &&
    css`
      color: #fcee21;
      font-size: 1.4vw;
    `};

  ${(props) =>
    props.siempre &&
    props.activo &&
    css`
      background-color: #fcee21;
      color: black;
      font-size: 1.4vw;
      &:hover {
        color: black;
      }
    `};
`;

export default Button;
