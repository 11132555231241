import axios from 'axios';
import * as API from './api';

export const saveArchivosService = async (
  file,
  { idPelicula, idBlog, idNewsletter, idGif }
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('pelicula', idPelicula ?? '');
  formData.append('blog', idBlog ?? '');
  formData.append('newsletter', idNewsletter ?? '');
  formData.append('gif', idGif ?? '');

  try {
    const res = await axios.post(API.archivos, formData, {
      headers: await API.Token({ isToken: true, formData: true }),
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
