import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { archivosFiles } from '../../../../services/api';
import {
  saveNewsletterAction,
  updateNewsletterAction,
} from '../../../../store/actions';

const FormNewsletter = ({ isModal }) => {
  const dispatch = useDispatch();

  const tiposBoletinState = useSelector((state) => state.tiposBoletin);
  const newsletterState = useSelector((state) => state.newsletter);

  const [fileGif, setFileGif] = useState();
  const [previewGif, setPreviewGif] = useState();

  // const [filePng, setFilePng] = useState();

  const [link, setLink] = useState(null);
  const [ancho, setAncho] = useState('85');
  const [anchoImg2, setAnchoImg2] = useState('85');
  const [anchoMovil, setAnchoMovil] = useState('90');
  const [anchoMovilImg2, setAnchoMovilImg2] = useState('90');
  const [posicion, setPosicion] = useState('1');

  const [publicar, setPublicar] = useState('1');

  useEffect(() => {
    setPosicion(newsletterState.all.length + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fileGif) {
      setPreviewGif(null);
      return;
    }
    const objectUrl = URL.createObjectURL(fileGif);
    setPreviewGif(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileGif]);

  // useEffect(() => {
  //   if (!filePng) {
  //     setPreviewPng(null);
  //     return;
  //   }
  //   const objectUrl = URL.createObjectURL(filePng);
  //   setPreviewPng(objectUrl);
  //   return () => URL.revokeObjectURL(objectUrl);
  // }, [filePng]);

  useEffect(() => {
    if (newsletterState.activo) {
      setLink(newsletterState.activo.link);
      setAncho(newsletterState.activo.ancho);
      setAnchoImg2(newsletterState.activo.anchoImg2);
      setAnchoMovil(newsletterState.activo.anchoMovil);
      setAnchoMovilImg2(newsletterState.activo.anchoMovilImg2);
      setPosicion(newsletterState.activo.posicion);
      setPublicar(newsletterState.activo.public ? '1' : '0');
    }
  }, [newsletterState.activo]);

  const [loadingSave, setLoadingSave] = useState(false);

  const _onSubmitHandler = async () => {
    const data = {
      link,
      ancho: Number(ancho),
      anchoMovil: Number(anchoMovil),
      anchoImg2: Number(anchoImg2),
      anchoMovilImg2: Number(anchoMovilImg2),
      posicion: Number(posicion),
      public: publicar === '1',
      tipoBoletin: tiposBoletinState.activo.id,
    };
    if (newsletterState.activo) {
      await updateNewsletterAction(dispatch, newsletterState.activo.id, data, {
        tipoBoletin: tiposBoletinState.activo.id,
      });
    } else {
      if (fileGif) {
        setLoadingSave(true);
        await saveNewsletterAction(dispatch, data, fileGif, null, {
          tipoBoletin: tiposBoletinState.activo.id,
        });
      }
    }
    setLoadingSave(false);
    isModal(false);
  };

  return (
    <Form>
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label for="Link">Link del video</Label>
            <Input
              id="link"
              name="link"
              required
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* <Col md={6}>
          <FormGroup>
            <Label for="tipo">Tipo*</Label>
            <Input
              id="tipo"
              name="select"
              type="select"
              required
              value={tipoBoletin}
              onChange={(e) => setTipoBoletin(e.target.value)}
            >
              {tiposBoletinState.all.map((el, i) => (
                <option key={i} value={el.id}>
                  {el.nombre}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col> */}
        <Col md={6}>
          <FormGroup>
            <Label for="ancho">
              Define al ancho de la imagén <strong>GIF/JPG</strong> en
              porcentaje del desktop (%)
            </Label>
            <Input
              id="ancho"
              name="ancho"
              required
              value={ancho}
              maxLength={2}
              onChange={(e) => setAncho(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* <Col md={6}>
          <FormGroup>
            <Label for="anchoImg2">
              Define al ancho de la imagén <strong>PNG</strong> en porcentaje
              del desktop (%)
            </Label>
            <Input
              id="anchoImg2"
              name="anchoImg2"
              required
              maxLength={2}
              value={anchoImg2}
              onChange={(e) => setAnchoImg2(e.target.value)}
            />
          </FormGroup>
        </Col> */}
        <Col md={6}>
          <FormGroup>
            <Label for="anchoMovil">
              Define al ancho de la imagén <strong>GIF/JPG</strong> en
              porcentaje del movil (%)
            </Label>
            <Input
              id="anchoMovil"
              name="anchoMovil"
              required
              maxLength={2}
              value={anchoMovil}
              onChange={(e) => setAnchoMovil(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* <Col md={6}>
          <FormGroup>
            <Label for="anchoMovilImg2">
              Define al ancho de la imagén <strong>PNG</strong> en porcentaje
              del movil (%)
            </Label>
            <Input
              id="anchoMovilImg2"
              name="anchoMovilImg2"
              required
              maxLength={2}
              value={anchoMovilImg2}
              onChange={(e) => setAnchoMovilImg2(e.target.value)}
            />
          </FormGroup>
        </Col> */}
        <Col md={6}>
          <FormGroup>
            <Label for="posicion">Define la posicion de la imagen</Label>
            <Input
              id="posicion"
              name="posicion"
              required
              value={posicion}
              onChange={(e) => setPosicion(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="tipo">Publicar la imagen *</Label>
            <Input
              id="tipo"
              name="select"
              type="select"
              required
              value={publicar}
              onChange={(e) => setPublicar(e.target.value)}
            >
              <option value="1" selected>
                ACTIVO
              </option>
              <option value="0">BLOQUEADO</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {newsletterState.activo ? null : (
            <FormGroup>
              <Label for="portada">Imagén GIF/JPG *</Label>
              <Input
                id="portada"
                name="portada"
                type="file"
                onChange={(e) => {
                  if (!e.target.files || e.target.files.length === 0) {
                    setFileGif(null);
                    return;
                  }
                  setFileGif(e.target.files[0]);
                  setPreviewGif(null);
                }}
              />
            </FormGroup>
          )}
          <img
            alt="GIF/JPG"
            src={
              previewGif
                ? previewGif
                : newsletterState.activo
                  ? `${archivosFiles}/${newsletterState.activo.gif.folder}/${newsletterState.activo.gif.nombre}`
                  : '../../img/picture.png'
            }
            width="30%"
          />
        </Col>
        {/* <Col md={6}>
          {newsletterState.activo ? null : (
            <FormGroup>
              <Label for="portada">Imagén de PNG*</Label>
              <Input
                id="portada"
                name="portada"
                type="file"
                onChange={(e) => {
                  if (!e.target.files || e.target.files.length === 0) {
                    setFilePng(null);
                    return;
                  }
                  setFilePng(e.target.files[0]);
                  setPreviewPng(null);
                }}
              />
            </FormGroup>
          )}
          <img
            src={
              previewPng
                ? previewPng
                : newsletterState.activo && newsletterState.activo.png
                ? `${archivosFiles}/${newsletterState.activo.png.folder}/${newsletterState.activo.png.nombre}`
                : '../../img/picture.png'
            }
            width={'30%'}
          />
        </Col> */}
      </Row>
      <Row>
        <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color={newsletterState.activo ? 'primary' : 'success'}
            onClick={_onSubmitHandler}
            disabled={loadingSave}
          >
            {loadingSave ? (
              <>
                <Spinner size="sm" />
                &nbsp;&nbsp;Guardando registro...
              </>
            ) : newsletterState.activo ? (
              'ACTUALIZAR'
            ) : (
              'GUARDAR'
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormNewsletter;
