import React from 'react';
import styled from 'styled-components';
import { device } from '../../../utils/constants';
import { useMediaQuery } from 'react-responsive';
import TitleCardServicios, { Titulo } from './components/title-card.servicios';
import { useTranslation } from 'react-i18next';
import { RED_COLOR } from '.';

const LogisticasServicios = () => {
  const mobile = useMediaQuery({ query: device.mobile });
  const { t } = useTranslation();

  return (
    <Body>
      <TitleCardServicios
        title={t('service.loc.logistica')}
        text={t('service.loc.logText')}
        images={[
          {
            src: '../../img/service/espacio.jpg',
          },
          {
            src: '../../img/service/carro.jpg',
          },
          {
            src: '../../img/service/cielo.jpg',
          },
        ]}
      />
      <div
        style={{
          width: '100vw',
          backgroundColor: RED_COLOR,
          padding: mobile ? '8vw 0' : '2vw 10vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '4vw',
          flexDirection: mobile ? 'column' : 'row',
        }}
      >
        <Titulo style={{ color: 'white' }}>{t('service.loc.alianza')}</Titulo>
        <img
          src="../../img/service/alianza.jpg"
          alt="location1"
          style={{
            width: mobile ? '40vw' : '10vw',
            height: mobile ? '40vw' : '10vw',
            margin: mobile ? '5vw  0' : '0vw 2vw',
          }}
        />
        <div style={{ width: mobile ? '100vw' : '30vw' }}>
          <label
            style={{
              fontFamily: 'FuturaLigth',
              color: 'white',
              fontSize: mobile ? '5vw' : null,
              textAlign: mobile ? 'center' : 'start',
            }}
          >
            {t('service.loc.aliText')}
          </label>
        </div>
      </div>
    </Body>
  );
};

export default LogisticasServicios;

const Body = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;

  //   &:hover .something {
  //     color: #fcee21;
  //     border-bottom: 1px solid #fcee21;
  //   }

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
`;
