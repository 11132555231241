import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutNosotros from '../nosotros/layoutNosotros';
import { SectionPublicidad } from './components';
import PublicidadComponent from './publicidad';

const PageCine = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  const movies = [
    {
      img: 'img/pub/new29042024-1.png',
      title: '',
      desc: 'MISS VENEZUELA EN LÍDER',
      url: 'https://youtu.be/QSDnVO93RMw?si=nYuGWU185SpI55IJ',
    },
    {
      img: 'img/pub/new29042024-2.png',
      title: '',
      desc: 'RON CARÚPANO XO',
      url: 'https://youtu.be/1UsE2pXo474?si=LDaTRljn69tK5VC2',
    },
    {
      img: 'img/pub/new29042024-3.png',
      title: '',
      desc: 'RON CARÚPANO XO - TEASER',
      url: 'https://youtu.be/ouIn6jMvfGg?si=QgZiywjuNr59CTuk',
    },
    {
      img: 'img/pub/new29042024-4.png',
      title: '',
      desc: 'MARATÓN CAF - CARACAS',
      url: 'https://youtu.be/2S3KH2ECGcc?si=95HLDRXVrJLLueT-',
    },
    {
      img: 'img/pub/new29042024-5.png',
      title: '',
      desc: 'MARATÓN CAF - ANIMADORES',
      url: 'https://youtu.be/8Z3i0TGNycw?si=GddA3SpswjzE_4G5',
    },
    {
      img: 'img/pub/new29042024-6.png',
      title: '',
      desc: 'MARATÓN CAF - RESUMEN COMPLETO',
      url: 'https://youtu.be/Cp5zZ5rFwv0?si=4kbxNxDqLgpnJH9K',
    },
    {
      img: 'img/pub/cuna_navidad.png',
      title: '',
      desc: 'DIGITEL - CUÑA NAVIDAD',
      url: 'https://player.vimeo.com/video/809971717',
    },
    {
      img: 'img/pub/lo_mejor.jpg',
      title: '',
      desc: 'PANTERA - LO MEJOR DE TI (VERSIÓN: LINDA)',
      url: 'https://player.vimeo.com/video/810622052',
    },
    {
      img: 'img/pub/lenguajes.jpg',
      title: '',
      desc: 'PLP - LENGUAJES (VERSIÓN: PUBLICIDAD)',
      url: 'https://player.vimeo.com/video/817612938',
    },
    {
      img: 'img/pub/cuna1.jpg',
      title: '',
      desc: 'JADU',
      url: 'https://player.vimeo.com/video/321765023',
    },
    {
      img: 'img/pub/cuna4.jpg',
      title: '',
      desc: 'MOVISTAR',
      url: 'https://player.vimeo.com/video/319596935',
    },
    {
      img: 'img/pub/cuna2.jpg',
      title: '',
      desc: 'FERRETOTAL',
      url: 'https://player.vimeo.com/video/318223497',
    },
    {
      img: 'img/pub/cuna3.jpg',
      title: '',
      desc: 'COCA-COLA',
      url: 'https://player.vimeo.com/video/318218855',
    },
    {
      img: 'img/pub/cuna5.jpg',
      title: '',
      desc: 'KRAFT',
      url: 'https://player.vimeo.com/video/333829619',
    },
    {
      img: 'img/pub/cuna6.jpg',
      title: '',
      desc: 'JEEP GRAND CHEROKEE 2011 - VERSIÓN ABREBOCAS',
      url: 'https://player.vimeo.com/video/318238637?h=12002683ee',
    },
    {
      img: 'img/pub/cuna7.jpg',
      title: '',
      desc: 'GRANOS PANTERA - TENEMOS UN COMPROMISO',
      url: 'https://player.vimeo.com/video/326689847?h=dc4641d207',
    },
    {
      img: 'img/pub/cuna8.jpg',
      title: '',
      desc: 'PANTERA - LO MEJOR DE TI (VERSIÓN: MARÍA CAROLINA)',
      url: 'https://player.vimeo.com/video/733245903?h=5a68372a3d',
    },
    {
      img: 'img/pub/cuna9.jpg',
      title: '',
      desc: 'RON SANTA TERESA - VERSIÓN SURF',
      url: 'https://player.vimeo.com/video/333830845?h=8b02d7334c',
    },
  ];

  return (
    <LayoutNosotros
      title={t('publicidad.publicidad')}
      subTitle="ESTO ES NUESTRO Y DE ELLOS"
    >
      <SectionPublicidad>
        {movies.map((el, index) => {
          return (
            <PublicidadComponent
              key={el.index}
              img={el.img}
              title={el.title}
              desc={el.desc}
              url={el.url}
            />
          );
        })}
      </SectionPublicidad>
    </LayoutNosotros>
  );
};

export default PageCine;
