import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link as Linka, useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';
import * as CONST from '../../../../common/const';
import * as ROUTES from '../../../../router/routes';
import {
  SET_IS_LEYENDA_IMG,
  SET_SECTION_LANDING,
} from '../../../../store/types';
import { device } from '../../../../utils/constants';
import { Button, Container, Logo } from './components';
import BotonLaCajaComponent from './components/BotonLaCaja';
import MenuHide, { ImagenCaleta } from './menu_hide';
import MenuMobile from './menu_mobile';

export const Menu = styled.div`
  height: 4vw;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  font-family: 'FuturaRegular';
  background-color: black;

  a {
    text-decoration: none;
  }

  ${(props) =>
    props.show &&
    css`
      animation: fadeIn 1s;
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `};
`;

const SocialBotones = styled.div`
  position: fixed;
  top: 10vw;
  left: 3.4vw;
  z-index: 999;
  /* background-color: black; */
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 1.5em 0;
  align-items: center;
`;

const Iconos = styled.img`
  width: ${({ w }) => w ?? '2.5vw'};
  margin-bottom: 15px;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
`;

const BotonLogin = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 4em;
  left: 1em;
  color: transparent;
`;

export const ButtonMenu = styled.div`
  position: fixed;
  top: 5vw;
  right: 5vw;
  z-index: 999;
`;

export const ButtonIcon = styled.img`
  width: 10vw;
  height: 10vw;
`;

const ButtonLang = styled.div`
  color: white;
  font-size: 1.4vw;
  cursor: pointer;
  color: ${({ white }) => (white ? 'white' : 'black')};
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  position: fixed;
  z-index: 999;
  right: 3vw;
  top: 3.5vw;
  padding: 5px;
`;

const Navbar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const layoutState = useSelector((state) => state.layout);
  const landingState = useSelector((state) => state.landing);
  const mobile = useMediaQuery({ query: device.mobile });
  const tablet = useMediaQuery({ query: device.tablet });
  const { t } = useTranslation();

  const [verSocial, setVerSocial] = useState(true);
  // const [header, setHeader] = useState(false)
  // const [conocenos, setConocenos] = useState(false)
  // const [nosotros, setNosotros] = useState(false)
  // const [lacaja, setLacaja] = useState(false)
  // const [relevante, setRelevante] = useState(false)
  // const [contacto, setContacto] = useState(false)
  const [logo, setLogo] = useState('img/logo.png');

  const [vimeoImg, setVimeoImg] = useState('img/vimeo.png');
  const [imdbImg, setImdbImg] = useState('img/imdb.png');
  const [twitterImg, setTwitterImg] = useState('img/twitter.png');
  const [instaImg, setInstaImg] = useState('img/insta.png');
  const [youtubeImg, setYoutubeImg] = useState('img/youtube.png');
  const [linkedImg, setLinkedImg] = useState('img/linkend.png');
  const [tiktokImg, setTiktokImg] = useState('img/tiktok.png');
  const [cajaFuerteImg, setCajaFuerteImg] = useState('img/lacajawhite.png');

  const [verMenu, setVerMenu] = useState(true);
  const [verMenuHover, setVerMenuHover] = useState(false);
  const [colorLang, setColorLang] = useState(true);
  const [finalScroll, setFinalScroll] = useState(false);
  const [isHideCaja, setIsHideCaja] = useState(false);

  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);

  const checkScrollTop = () => {
    if (document.getElementById(CONST.HEADER) === null) return;
    let header = document.getElementById(CONST.HEADER).clientHeight;
    let conocenos = document.getElementById('conocenos').clientHeight;
    let publicidad = document.getElementById(CONST.PUBLICIDAD).clientHeight;
    let tags = document.getElementById('tags').clientHeight;
    let lacaja = document.getElementById(CONST.LA_CAJA).clientHeight;
    let contacto = document.getElementById('contacto').clientHeight;

    const headerVar = header;
    const nosotrosVar = headerVar + conocenos + publicidad + tags;
    const relevanteVar = nosotrosVar;
    const lacajaVar = relevanteVar + lacaja;
    setLogo('img/logoblack.png');
    if (
      window.pageYOffset > relevanteVar - 250 &&
      window.pageYOffset < lacajaVar - 400
    ) {
      setVimeoImg('img/vimeoblack.png');
    } else {
      setVimeoImg('img/vimeo.png');
    }
    if (
      window.pageYOffset > relevanteVar - 300 &&
      window.pageYOffset < lacajaVar - 400
    ) {
      setImdbImg('img/imdbblack.png');
    } else {
      setImdbImg('img/imdb.png');
    }
    if (
      window.pageYOffset > relevanteVar - 350 &&
      window.pageYOffset < lacajaVar - 400
    ) {
      setTwitterImg('img/twitterblack.png');
    } else {
      setTwitterImg('img/twitter.png');
    }
    if (
      window.pageYOffset > relevanteVar - 400 &&
      window.pageYOffset < lacajaVar - 400
    ) {
      setInstaImg('img/instablack.png');
    } else {
      setInstaImg('img/insta.png');
    }
    if (
      window.pageYOffset > relevanteVar - 450 &&
      window.pageYOffset < lacajaVar - 400
    ) {
      setYoutubeImg('img/youtubeblack.png');
    } else {
      setYoutubeImg('img/youtube.png');
    }
    if (
      window.pageYOffset > relevanteVar - 500 &&
      window.pageYOffset < lacajaVar - 400
    ) {
      setTiktokImg('img/tiktokblack.png');
    } else {
      setTiktokImg('img/tiktok.png');
    }
    if (
      window.pageYOffset > relevanteVar - 550 &&
      window.pageYOffset < lacajaVar - 400
    ) {
      setLinkedImg('img/linkendblack.png');
    } else {
      setLinkedImg('img/linkend.png');
    }
    if (
      window.pageYOffset > relevanteVar - 800 &&
      window.pageYOffset < lacajaVar - 600
    ) {
      setCajaFuerteImg('img/lacajablack.png');
    } else {
      setCajaFuerteImg('img/lacajawhite.png');
    }

    if (window.pageYOffset > relevanteVar + 400) {
      setVerSocial(false);
    } else {
      setVerSocial(true);
    }
    if (window.pageYOffset > relevanteVar) {
      setLogo('img/logoblack.png');
      setColorLang(false);
    } else {
      setLogo('img/logo.png');
      setColorLang(true);
    }
    if (window.pageYOffset > 300) {
      setVerMenu(false);
      setVerMenuHover(false);
    } else {
      setVerMenu(true);
      setVerMenuHover(false);
    }

    const xcc = relevanteVar + contacto - 60;
    setFinalScroll(window.pageYOffset > xcc);
    setIsHideCaja(window.pageYOffset >= relevanteVar + contacto - 30);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    setColorLang(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (document.getElementById(CONST.HEADER) === null) return;
    const header = document.getElementById(CONST.HEADER).clientHeight;
    const conocenos = document.getElementById('conocenos').clientHeight;
    const publicidad = document.getElementById(CONST.PUBLICIDAD).clientHeight;
    const tags = document.getElementById('tags').clientHeight;

    setTimeout(() => window.scrollTo(0, 0), 500);

    const publicidadHeigth = header + conocenos;
    if (landingState.section === CONST.PUBLICIDAD) {
      setTimeout(() => window.scrollTo(0, publicidadHeigth), 500);
    }

    const relevanteHeigth = publicidadHeigth + publicidad + tags;
    if (landingState.section === CONST.RELEVANTE) {
      setTimeout(() => window.scrollTo(0, relevanteHeigth), 500);
    }

    if (landingState.section === CONST.LA_CAJA) {
      setTimeout(() => window.scrollTo(0, relevanteHeigth), 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingState.section]);

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handlerOnMenu = (section) => {
    setSection(section);

    setTimeout(() => {
      if (section !== CONST.LA_CAJA) {
        setSection(CONST.HEADER);
      }
    }, 2000);
    if (mobile) setIsOpenMenuMobile(false);
  };

  const setSection = (section) =>
    dispatch({
      type: SET_SECTION_LANDING,
      payload: section,
    });

  useEffect(() => {
    document.body.style.overflow = isOpenMenuMobile ? 'hidden' : '';
  }, [isOpenMenuMobile]);

  return (
    <>
      {mobile ? null : (
        <Linka to={ROUTES.LOGIN}>
          <BotonLogin> LOGIN </BotonLogin>
        </Linka>
      )}
      {!verSocial || mobile ? null : (
        <SocialBotones>
          {finalScroll ? null : (
            <Iconos
              onClick={() =>
                openInNewTab(
                  'https://www.youtube.com/channel/UC3ra91gf1g2RToJ7nwjRG8g'
                )
              }
              src={youtubeImg}
            />
          )}
          {finalScroll ? null : (
            <Iconos
              onClick={() =>
                openInNewTab(
                  'https://www.linkedin.com/company/74562983/admin/feed/posts/'
                )
              }
              src={linkedImg}
            />
          )}
          {finalScroll ? null : (
            <Iconos
              onClick={() =>
                openInNewTab(
                  'https://instagram.com/palospanasprod?igshid=1t0x76sa705f0'
                )
              }
              src={instaImg}
            />
          )}
          {finalScroll ? null : (
            <Iconos
              onClick={() => openInNewTab('https://twitter.com/palospanasprod')}
              src={twitterImg}
            />
          )}
          {finalScroll ? null : (
            <Iconos
              w="2.2vw"
              onClick={() =>
                openInNewTab('https://www.tiktok.com/@palospanasprod')
              }
              src={tiktokImg}
            />
          )}
          {finalScroll ? null : (
            <Iconos
              onClick={() => openInNewTab('https://vimeo.com/palospanas')}
              src={vimeoImg}
            />
          )}
          {finalScroll ? null : (
            <Iconos
              w="3.2vw"
              onClick={() =>
                openInNewTab(
                  'https://www.imdb.com/search/title/?companies=co0531373'
                )
              }
              src={imdbImg}
            />
          )}
        </SocialBotones>
      )}
      <Logo
        src={logo}
        onClick={() => {
          history.push('/');
        }}
      />
      {mobile ? (
        !isOpenMenuMobile ? null : (
          <MenuMobile
            section={landingState.section}
            onMenu={handlerOnMenu}
            isLanding
          />
        )
      ) : verMenu ? (
        <Container>
          <Menu show={verMenu}>
            <Link
              to={CONST.HEADER}
              spy
              smooth
              duration={800}
              onClick={() => handlerOnMenu(CONST.HEADER)}
            >
              <Button activo={landingState.section === CONST.HEADER}>
                {t('menu.home')}
              </Button>
            </Link>
            <Linka to={ROUTES.NOSOTROS}>
              <Button>{t('menu.nosotro')}</Button>
            </Linka>
            {/* <Link
              to={CONST.PUBLICIDAD}
              spy
              smooth
              duration={800}
              onClick={() => handlerOnMenu(CONST.PUBLICIDAD)}
            >
              <Button activo={landingState.section === CONST.PUBLICIDAD}>
                {t("menu.lenguaje")}
              </Button>
            </Link> */}
            <Link
              to={CONST.LA_CAJA}
              spy
              smooth
              duration={800}
              onClick={() => handlerOnMenu(CONST.LA_CAJA)}
            >
              <Button
                activo={landingState.section === 5}
                siempre
                isHover
                ismouse
                onMouseEnter={() => {
                  dispatch({
                    type: SET_IS_LEYENDA_IMG,
                    payload: true,
                  });
                }}
                onMouseLeave={() => {
                  dispatch({
                    type: SET_IS_LEYENDA_IMG,
                    payload: false,
                  });
                }}
                onClick={() => {
                  setTimeout(() => {
                    dispatch({
                      type: SET_IS_LEYENDA_IMG,
                      payload: false,
                    });
                  }, 200);
                }}
              >
                {t('menu.caja')}
              </Button>
            </Link>
            {/* <Link
              to={CONST.RELEVANTE}
              spy
              smooth
              duration={800}
              onClick={() => handlerOnMenu(CONST.RELEVANTE)}
            >
              <Button activo={landingState.section === CONST.RELEVANTE}>
                {t("menu.relevante")}
              </Button>
            </Link> */}
            <Linka to={ROUTES.NEWSLETTER}>
              <Button>{t('menu.news')}</Button>
            </Linka>
            <Linka to={ROUTES.SERVICIOS}>
              <Button>{t('menu.service')}</Button>
            </Linka>
            {/* <Linka to={ROUTES.CONTACTO}>
              <Button>{t("menu.contacto")}</Button>
            </Linka> */}
            <a href="mailto:contacto@palospanas.com">
              <Button>{t('menu.aplica')}</Button>
            </a>
          </Menu>
          {layoutState.isLeyendaImg ? (
            <ImagenCaleta>
              <img width="100%" src="../../img/imgCaleta.png" alt="caleta" />
            </ImagenCaleta>
          ) : null}
        </Container>
      ) : (
        <MenuHide
          menuHover={setVerMenuHover}
          onClickHeader={() => setSection(CONST.HEADER)}
          onClickPublicidad={() => setSection(CONST.PUBLICIDAD)}
          onClickRelevante={() => setSection(CONST.RELEVANTE)}
          onClickLaCaja={() => setSection(CONST.LA_CAJA)}
          verMenuHover={verMenuHover}
          section={landingState.section}
          isLanding
        />
      )}
      {mobile || tablet ? (
        <ButtonMenu
          onClick={() => {
            setLogo('img/logo.png');
            setIsOpenMenuMobile(!isOpenMenuMobile);
          }}
        >
          <ButtonIcon
            src={`img/${isOpenMenuMobile ? 'closeWhite.png' : 'menuWhite.png'}`}
          />
        </ButtonMenu>
      ) : null}
      {mobile ? null : (
        <ButtonLang
          alt="Cambio de Idioma"
          white={colorLang}
          onClick={() => {
            props.changeLang(props.lang === 'en' ? 'es' : 'en');
            window.location.reload();
          }}
        >
          {props.lang === 'en' ? 'EN' : 'ES'}{' '}
          <FaAngleDown style={{ color: '#FCEE21' }} />{' '}
        </ButtonLang>
      )}
      {isHideCaja ? null : (
        <BotonLaCajaComponent
          src={cajaFuerteImg}
          onClick={() => {
            let header = document.getElementById(CONST.HEADER).clientHeight;
            let conocenos = document.getElementById('conocenos').clientHeight;
            let publicidad = document.getElementById(
              CONST.PUBLICIDAD
            ).clientHeight;
            let tags = document.getElementById('tags').clientHeight;

            const headerVar = header;
            const nosotrosVar = headerVar + conocenos + publicidad + tags;
            const relevanteVar = nosotrosVar;
            window.scrollTo(0, relevanteVar);
          }}
        />
      )}
    </>
  );
};

export default Navbar;
