import React, { useEffect } from "react";
import { SectionForm, SectionMisVideos } from "./components";
import { useTranslation } from "react-i18next";
import LaCaja from "../landing-page/lacaja/lacaja";
import LayoutNosotros from "../nosotros/layoutNosotros";
import { useDispatch, useSelector } from "react-redux";
import { SET_CAJA_FUERTE, SET_VIDEO_PROPAGANDA } from "../../../store/types";
import CardPropagandaComponent from "./cardPropaganda";
import { MIS_VIDEOS } from "../../../common/const";
import SectionVideoComponent from "./SectionVideo";
import { GOBIERNO, MUD, PLAN, SPOTS } from "../../../router/routes";
import { useHistory } from "react-router-dom";
// import BotonLaCajaComponent from '../landing-page/navbar/components/BotonLaCaja';

const PagePropaganda = () => {
  const dispatch = useDispatch();
  const layoutState = useSelector((state) => state.layout);
  let history = useHistory();

  const { t } = useTranslation();

  const handleDispatch = () => {
    if (layoutState.cajaFuerte) {
      let h = document.getElementById("sectionTitulo").clientHeight;
      window.scrollTo(0, h);
      dispatch({
        type: SET_CAJA_FUERTE,
        payload: false,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    const x = localStorage.getItem(MIS_VIDEOS);
    dispatch({
      type: SET_CAJA_FUERTE,
      payload: false,
    });
    dispatch({
      type: SET_VIDEO_PROPAGANDA,
      payload: x === "true",
    });
    handleDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutNosotros
      title={t("publicidad.propaganda")}
      subTitle={t("publicidad.proText")}
      isSmooth
    >
      {layoutState.isVideoPropaganda ? (
        <>
          <SectionMisVideos>
            <CardPropagandaComponent
              img={"img/prop_Plan_pais.png"}
              title="PLAN PAÍS"
              onClick={() => history.push(PLAN)}
            />
            <CardPropagandaComponent
              img={"img/prop_Gob_interino.png"}
              title="GOBIERNO INTERINO"
              onClick={() => history.push(GOBIERNO)}
            />
            <CardPropagandaComponent
              img={"img/prop_Mud.png"}
              title="MUD 2021"
              onClick={() => history.push(MUD)}
            />
          </SectionMisVideos>
          {layoutState.isVideoPropaganda ? (
            <CardPropagandaComponent
              img={"img/imgSpots.png"}
              title="SPOTS"
              full
              onClick={() => history.push(SPOTS)}
            />
          ) : (
            <SectionVideoComponent />
          )}
        </>
      ) : (
        <>
          <SectionVideoComponent />
          <SectionForm>
            <LaCaja lacaja codes={true} isRoute={false} isPropaganda />
          </SectionForm>
        </>
      )}
      {/* <BotonLaCajaComponent
            src={layoutState.isVideoPropaganda ? "img/closeYellow.png" : null}
            onClick={() => {
                if (layoutState.isVideoPropaganda) {
                    dispatch({
                        type: SET_VIDEO_PROPAGANDA,
                        payload: false
                    })
                    localStorage.setItem(MIS_VIDEOS, false)
                    dispatch({
                        type: SET_CODIGOS_ACTIVO,
                        payload: null
                    })
                } else {
                    dispatch({
                        type: SET_IS_CODE_CONTACTO,
                        payload: true
                    })
                    history.push(CONTACTO)
                }
            }}
        /> */}
    </LayoutNosotros>
  );
};

export default PagePropaganda;
